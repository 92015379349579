import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import useSavedCandidates from '../../../../../utils/hooks/useSavedCandidates';
import CandidateCardWide from '../../../../common/CandidateCardWide';
import './index.scss';

const ProfilePageSavedCandidates = () => {
  const intl = useIntl();

  const savedCandidates = useSavedCandidates();

  return (
    <div className="profile-page-saved-candidates">
      {savedCandidates.list?.length ? (
        savedCandidates.list.map(candidate => (
          <Fragment key={candidate.id}>
            <CandidateCardWide
              className="profile-page-saved-candidates__card"
              candidate={candidate}
              removeSavedCandidate={savedCandidates.remove}
            />
          </Fragment>
        ))
      ) : (
        <p className="profile-page-saved-candidates__fallback-message">{intl.messages['profile.content.noSavedCandidates']}</p>
      )}
    </div>
  );
};

export default ProfilePageSavedCandidates;
