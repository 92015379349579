import React from 'react';
import * as classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const SlickPreviousArrow = props => {
  const { className, onClick } = props;
  return (
    <div
      className={classnames(
        'slick-previous-arrow',
        className && className,
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        className={classnames(
          'slick-previous-arrow__chevron',
        )}
      />
    </div>
  );
};

export default SlickPreviousArrow;
