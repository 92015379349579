import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const VideoButton = props => {
  const {
    className,
    label,
    handleClick,
  } = props;

  return (
    <div
      className={classnames(
        'video-button',
        className && className,
      )}
      onClick={handleClick}
    >
      <div className="video-button__icon" />
      <p className="video-button__label">
        {label}
      </p>
    </div>
  );
};

export default VideoButton;
