import React from 'react';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import CloseButtonBig from '../ui/Buttons/CloseButtonBig';
import './index.scss';

const VideoModal = () => {
  const [{ videoModalData }, dispatch] = React.useContext(AppContext);
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false);

  const videoElement = React.useRef(null);

  const hidden = !videoModalData?.url;

  const toggleVideoPlay = () => {
    if (isVideoPlaying) {
      videoElement.current.pause();
      setIsVideoPlaying(false);
    } else {
      videoElement.current.play();
      setIsVideoPlaying(true);
    }
  };

  const closeModal = () => {
    dispatch({ type: 'nullVideoModalData' });
  };

  return (
    <div 
      className={classnames(
        'video-modal',
        hidden && '-hidden',
      )}
    >
      {!hidden && (
        <>
          <video
            className="video-modal__video"
            ref={videoElement}
            onClick={toggleVideoPlay}
          >
            <source src={videoModalData.url} type="video/mp4" />
          </video>
          <FontAwesomeIcon
            icon={faCirclePlay}
            className={classnames(
              'video-modal__play-button',
              isVideoPlaying && '-hidden',
            )}
            onClick={toggleVideoPlay}
          />
          <CloseButtonBig
            className="video-modal__close-button"
            handleClick={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default VideoModal;
