import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as classnames from 'classnames';
import { comparePrimitiveObjectsWithSameStructure, objectHasOnlyFalsyValues } from '../../../utils/helpers';
import { myCandidateSpecialsSchema } from '../../../utils/validationSchemas';
import TextareaField from '../../common/ui/TextareaField';
import Button from '../../common/ui/Buttons/Button';
import LevelSelector from '../../common/ui/LevelSelector';

const MyCandidateSpecialsForm = (props) => {
  const { initialValues, onSubmit, stepForward, stepBack } = props;

  const intl = useIntl();

  const ERROR_MESSAGES = {
    TOO_LONG: intl.messages['error.tooLong'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={myCandidateSpecialsSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form className="my-candidate-page-specials__form" onSubmit={handleSubmit}>
          <TextareaField
            className="my-candidate-page-specials__form-textarea"
            placeholder={intl.messages['common.writeHere']}
            name="specialsText"
            value={values.specialsText}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.specialsText}
          />
          <p className="my-candidate-page-specials__level-description">
            {intl.messages['myCandidate.levelDescription']}
          </p>
          <LevelSelector
            className="my-candidate-page-specials__level-selector"
            name="specialsImportance"
            value={values.specialsImportance}
            onChange={level => setFieldValue('specialsImportance', level)}
          />
          <div className="my-candidate-page-specials__button-container">
            <Button
              className="my-candidate-page-specials__button"
              label={intl.messages['common.previous']}
              handleClick={stepBack}
              disabled={isSubmitting}
            />
            {comparePrimitiveObjectsWithSameStructure(initialValues, values) ? (
              <Button
                className={classnames(
                  'my-candidate-page-specials__button',
                  '-secondary',
                )}
                label={objectHasOnlyFalsyValues(values) ? intl.messages['common.skip'] : intl.messages['common.next']}
                handleClick={stepForward}
                disabled={isSubmitting}
              />
            ) : (
              <Button
                className={classnames(
                  'my-candidate-page-specials__button',
                  '-secondary',
                )}
                label={intl.messages['common.saveAndNext']}
                type='submit'
                onSubmit={handleSubmit}
                disabled={isSubmitting || Object.keys(errors).length}
              />
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

export default MyCandidateSpecialsForm;
