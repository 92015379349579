import React from 'react';
import SlickNextArrow from '../../common/ui/ReactSlickCustom/SlickNextArrow';
import SlickPreviousArrow from '../../common/ui/ReactSlickCustom/SlickPreviousArrow';
import SlickDots from '../../common/ui/ReactSlickCustom/SlickDots';

const useSliderSettings = () => {
  const desktop = {
    className: 'case-page-desktop-case__slider',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPreviousArrow />,
    appendDots: dots => <SlickDots dots={dots} />,
  };

  const mobile = {
    className: 'case-page-mobile-case__slider',
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
  };

  return { desktop, mobile };
};

export default useSliderSettings;
