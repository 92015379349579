import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PATHS } from '../../../utils/constants';
import useResolveScreen from '../../../utils/hooks/useResolveScreen';
import useFaq from '../../../utils/hooks/useFaq';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import FaqItem from '../../common/FaqItem';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import BackButton from '../../common/ui/Buttons/BackButton';
import Button34 from '../../common/ui/Buttons/Button34';
import './index.scss';

const ProfileFaqPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const faq = useFaq();
  const screen = useResolveScreen(BREAKPOINTS.md);

  React.useEffect(() => {
    if (screen) {
      if (screen.isMobile === false) {
        navigate(PATHS.profile);
      }
    }
  }, [navigate, screen]);

  return (
    <div className="profile-faq-page">
      <div className="profile-faq-page__container">
        <TopMenuLayerMobile />
        <div className="profile-faq-page__title">
          {intl.messages['profile.menu.myCandidate']}
        </div>
        <div className="profile-faq-page__content">
          {faq.list?.length ? (
            faq.list?.map((faq, i) => (
              <FaqItem
                key={i}
                faq={faq}
              />
            ))) : (
            <p
              className="profile-faq-page__fallback-message"
              dangerouslySetInnerHTML={{ __html: intl.messages['profile.content.noFaq'] }}
            ></p>
          )}
        </div>
        <div className="profile-faq-page__bottom-section">
          <div className="profile-faq-page__button-container">
            <Button34
              className="profile-faq-page__button"
              label={intl.messages['profile.menu.title']}
              handleClick={() => navigate(PATHS.profile)}
            />
          </div>
          <BackButton className="profile-faq-page__back-button" />
        </div>
      </div>
    </div>
  );
};

export default ProfileFaqPage;
