import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../../storage/context';
import { MY_CANDIDATE_STEPS, PATHS } from '../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../common/ui/Buttons/Button';
import './index.scss'

const MyCandidatePageSuccess = () => {
  const { myCandidate } = React.useContext(AppContext)[0];

  const navigate = useNavigate();
  const intl = useIntl();

  const myCandidateId = myCandidate?.id;

  React.useEffect(() => {
    if (!myCandidateId) {
      navigate(`${PATHS.myCandidate}/${MY_CANDIDATE_STEPS.find(step => step.order === 2)?.slug}`);
    }
  }, [myCandidateId, navigate]);

  return (
    <div className="my-candidate-page-success">
      <div className="my-candidate-page-success__avatar-container">
        <div className="my-candidate-page-success__avatar">
          <FontAwesomeIcon icon={faUser} className="my-candidate-page-success__avatar-image" />
          <div className="my-candidate-page-success__checkmark"></div>
        </div>
      </div>
      <h2 className="my-candidate-page-success__title">
        {myCandidate?.name}
        &nbsp;{intl.messages['myCandidate.success.title']}
      </h2>
      <p
        className="my-candidate-page-success__description"
        dangerouslySetInnerHTML={{ __html: intl.messages['myCandidate.success.description'] }}
      />
      <Button
        className={classnames(
          'my-candidate-page-success__button',
          '-secondary',
        )}
        label={intl.messages['common.ok']}
        handleClick={() => navigate(PATHS.menu)}
      />
    </div>
  );
};

export default MyCandidatePageSuccess;
