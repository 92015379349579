import React from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../storage/context';
import MyCandidateNameForm from '../../../forms/MyCandidateNameForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss'

const MyCandidatePageName = props => {
  const { myCandidate } = React.useContext(AppContext)[0];
  const { addName, stepBack } = props;
  const intl = useIntl();

  const myCandidateUserEmail = myCandidate?.userEmail;

  const initialValues = {
    name: myCandidate?.name || '',
  };


  React.useEffect(() => {
    if (!myCandidateUserEmail) {
      stepBack();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCandidateUserEmail]);

  return (
    <div className="my-candidate-page-name">
      <div className="my-candidate-page-name__data-container-mobile">
        <h3 className="my-candidate-page-name__data-mobile">{myCandidate?.userEmail || ''}</h3>
        <h3 className="my-candidate-page-name__data-mobile">{myCandidate?.name || ''}</h3>
      </div>
      <div className="my-candidate-page-name__avatar-container">
        <div className="my-candidate-page-name__avatar">
          <FontAwesomeIcon icon={faUser} className="my-candidate-page-name__avatar-image" />
        </div>
      </div>
      <h2 className="my-candidate-page-name__title">
        {intl.messages['myCandidate.start.title']}
        <span className="my-candidate-page-name__title -secondary">&nbsp;{intl.messages['myCandidate.start.candidate']}</span>
      </h2>
      <MyCandidateNameForm
        initialValues={initialValues}
        onSubmit={addName}
      />
    </div>
  );
};

export default MyCandidatePageName;
