import axios from "axios";
import { handleErrorSet } from "../../../utils/fetchUtils";
import Paths from "../../../utils/paths";
import { API_URL } from "../../../utils/constants";

export const getTranslations = async (setErrorStatus) => {
  try {
    const response = await axios.get(
      `${API_URL}/${Paths.Translation.translations}`
    );
    const { data = {}, status = 0 } = response || {};
    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const {
        messages: [errorObject],
      } = messages;
      const errorMessage = (errorObject && errorObject.message) || "";
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  } catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};
