import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const TextareaField = props => {
  const { name, label, className, error, column, text, ...other } = props;
  const textAreaClass = classnames(
    'textarea-field__input',
    error === '' && 'textarea-field__input-error',
    { textarea_error_status: error }
  );

  return (
    <div
      className={classnames(
        'textarea-field',
        column && 'column',
        className && className,
      )}
    >
      <div className="textarea-field__top">
        {label && (
          <label className="textarea-field__label" htmlFor={name}>
            {label}
          </label>
        )}
        {text && <span className="textarea-field__text">{text}</span>}
      </div>
      <textarea rows="13" className={textAreaClass} name={name} id={name} {...other} />
      <div className="textarea-field__error">{error}</div>
    </div>
  );
};

export default TextareaField;
