import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import LanguageSelectMenu from '../../common/LanguageSelectMenu';
import { PATHS } from '../../../utils/constants';
import Button from '../../common/ui/Buttons/Button';
import './index.scss';

const Error404Page = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div className="error-404-page">
      <div className="error-404-page__container">
        <div className="error-404-page__content">
          <LanguageSelectMenu
            className="error-404-page__language-select-menu"
          />
          <div className="error-404-page__title">
            {intl.messages['error404.title']}
          </div>
          <p
            className="error-404-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['error404.description'] }}
          ></p>
          <Button
              className='error-404-page__button -secondary'
              label={intl.messages['common.return']}
              handleClick={() => navigate(PATHS.root)}
          />
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
