import React from 'react';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../../common/ScreenResolver';
import ProfilePageDesktop from './ProfilePageDesktop';
import ProfilePageMobile from './ProfilePageMobile';
import './index.scss';

const ProfilePage = () => {
  return (
    <div className="profile-page">
      <div className="profile-page__container">
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <ProfilePageDesktop
              className="profile-page__desktop"
            />
          }
          mobile={
            <ProfilePageMobile
              className="profile-page__mobile"
            />
          }
        />
      </div>
    </div>
  );
};

export default ProfilePage;
