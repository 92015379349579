import React from 'react';
import { LANGUAGE, LANGUAGES } from '../../../utils/constants';
import './index.scss';

const FaqItem = props => {
  const { faq } = props;

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  return (
    <div className="faq-item">
      <p className="faq-item__question">{faq[selectedLanguageCode].question}</p>
      <p className="faq-item__answer">{faq[selectedLanguageCode].answer}</p>
    </div>
  );
};

export default FaqItem;
