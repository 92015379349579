import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TOP, MATCH } from '../../../utils/constants';
import { getUrlParams } from '../../../utils/helpers';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { AppContext } from '../../../storage/context';
import LanguageSelectMenu from '../../common/LanguageSelectMenu';
import BackButton from '../../common/ui/Buttons/BackButton';
import PasswordResetForm from '../../forms/PasswordResetForm';
import { postResetPassword } from '../../../api/services/Auth';
import './index.scss';

const PasswordResetPage = () => {
  const dispatch = React.useContext(AppContext)[1];

  const { search } = useLocation();
  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { code } = getUrlParams(search);

  const resetPassword = async (values, { setSubmitting }) => {
    const payload = {
      code,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    };

    try {
      loadingLayer.show();
      const response = await postResetPassword(payload, setError);

      if (response) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages['modal.resetPassword.message'],
          },
        });
      }
    } catch(error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }

    setSubmitting(false);
  };

  return (
    <div className="password-reset-page">
      <div className="password-reset-page__container">
        <LanguageSelectMenu
          className="password-reset-page__language-select-menu"
        />
        <div className="password-reset-page__title">
          <h1 className="password-reset-page__title-front">{TOP}</h1>
          <h1 className="password-reset-page__title-back">{MATCH}</h1>
        </div>
        <h3
          className="password-reset-page__reset"
          dangerouslySetInnerHTML={{ __html: intl.messages['resetPassword.title'] }}
        ></h3>
        <p
          className="password-reset-page__description"
          dangerouslySetInnerHTML={{ __html: intl.messages['resetPassword.description'] }}
        ></p>
        <PasswordResetForm onSubmit={resetPassword} />
      </div>
      <BackButton />
    </div>
  );
};

export default PasswordResetPage;
