import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import Checkbox from '../../common/ui/Checkbox';
import Button45 from '../../common/ui/Buttons/Button45';
import './index.scss';

const DontShowForm = (props) => {
  const { onSubmit } = props;

  const intl = useIntl();

  const initialValues = {
    dontShow: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form className="dont-show-form" onSubmit={handleSubmit}>
          <div className="dont-show-form__checkbox-container">
            <Checkbox
              className="dont-show-form__checkbox"
              name="dontShow"
              text=""
              onChange={handleChange}
              value={values.acceptTerms}
            />
            <p className="dont-show-form__checkbox-text">
              {intl.messages['common.dontShow']}
            </p>
          </div>
          <div className="dont-show-form__buttons">
            <Button45
              className='dont-show-form__button -primary-dark'
              label={intl.messages['common.ok']}
              boldLabel
              type='submit'
              onSubmit={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default DontShowForm;
