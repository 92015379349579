import React from 'react';
import { useNavigate } from "react-router-dom";
import * as classnames from 'classnames';
import './index.scss';

const MenuButton = (props) => {
  const {
    className,
    label = '',
    navigateTo,
    handleClick,
    disabled
  } = props;

  const navigate = useNavigate();

  return (
    <div
      className={classnames(
        'menu-button',
        className && className,
        disabled && '-disabled',
      )}
      onClick={
        () => {
          if (handleClick) {
            handleClick();
          } else if (navigateTo) {
            navigate(navigateTo);
          }
        }
      }
    >
      <div
        className={classnames(
          'menu-button__label',
          disabled && '-disabled',
        )}
        dangerouslySetInnerHTML={{ __html: label }}
      >
      </div>
    </div>
  );
};

export default MenuButton;
