import React, { useContext, useState, useEffect, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import useSetError from '../../../utils/hooks/useSetError';
import { getTranslations } from '../../../api/services/Intl';
import { AppContext } from '../../../storage/context';
import { LANGUAGE, LANGUAGES } from '../../../utils/constants';

const Internationalization = ({ children }) => {
  const [messages, setMessages] = useState({ key: 'dk' });
  const [{ intl = {} , selectedLanguage = localStorage.getItem(LANGUAGE) }, dispatch] = useContext(AppContext);

  const setError = useSetError();

  const fetchTranslations = useCallback(async () => {
    if (!selectedLanguage) {
      localStorage.setItem(LANGUAGE, LANGUAGES.DK);
      dispatch({ type: 'setSelectedLanguage', selected: LANGUAGES.DK });
    }

    try {
      const translations = await getTranslations(setError);
      if (translations) {
        dispatch({
          type: 'setIntl',
          intl: translations,
        });
      }
    } catch(err) {
      console.error(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setError]);

  const createMessages = useCallback(() => {
    const messages = {};
    const translationExists = !!intl && !!Object.keys(intl).length;
    const isLanguageSelected = !!selectedLanguage && !!intl[selectedLanguage];

    if (translationExists && isLanguageSelected) {
      intl[selectedLanguage].forEach(item => {
        const key = Object.keys(item);
        messages[key] = item[key];
      });
      setMessages(messages);
    }
  }, [intl, selectedLanguage]);

  useEffect(() => {
    createMessages();
  }, [createMessages]);

  useEffect(() => {
    fetchTranslations();
  }, [fetchTranslations]);

  // const isIntlReady = !!Object.keys(intl).length;
  return (
    <IntlProvider locale={selectedLanguage} messages={messages} onError={() => { }}>
      {children}
    </IntlProvider>
  );
};

export default Internationalization;
