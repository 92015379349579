import colors from '../colors';
import { BREAKPOINTS } from "../breakpoints";
import { singleValueSelect } from './singleValueSelect';

export const multiValueSelect = {
  ...singleValueSelect,
  multiValue: provided => ({
    ...provided,
    position: 'relative',
    backgroundColor: 'transparent',
    fontWeight: '600',
  }),
  multiValueLabel: provided => {
    const multiValueLabel = {
      ...provided,
      fontSize: '1.11vmin',
      textTransform: 'uppercase',
      backgroundColor: colors.secondaryLightest,
      color: colors.black,
      paddingRight: '2.22vmin',
      borderRadius: '0.93vmin',
    };
    multiValueLabel[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      fontSize: '12px',
      paddingRight: '24px',
      borderRadius: '10px',
    };

    return multiValueLabel;
  },
  multiValueRemove: provided => {
    const multiValueRemove = {
      ...provided,
      color: colors.greyLight,
      backgroundColor: colors.white,
      position: 'absolute',
      right: '0.46vmin',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '1.30vmin',
      height: '1.30vmin',
      padding: '0',
      borderRadius: '0.93vmin',
      transitionDuration: '0.3s',
  
      '&:hover': {
        backgroundColor: colors.secondary,
        color: colors.white,
        cursor: 'pointer',
        transitionDuration: '0.3s',
      }
    };
    multiValueRemove[`@media (max-width: ${BREAKPOINTS.md}px)`] = {
      right: '5px',
      width: '14px',
      height: '14px',
      borderRadius: '10px',
    };

    return multiValueRemove;
  },
};
