import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Slider = (props) => {
  const { className, title, name, isChecked, onChange = () => {} } = props;

  return (
    <div className={classnames(
      'toggle-slider',
      className && className,
    )}>
      {
        title && 
        <h4
          className="toggle-slider__title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h4>
      }
      <label className="toggle-slider__container">
        <input className="toggle-slider__checkbox" type="checkbox" name={name || ''} checked={isChecked} onChange={onChange} />
        <span className="toggle-slider__body"></span>
      </label>
    </div>
  );
};

export default Slider;
