import protectedHttp from "../../ProtectedHTTP";
import { handleErrorSet } from "../../../utils/fetchUtils";
import Paths from "../../../utils/paths";

export const postTrackingEvent = async (body, setErrorStatus) => {
  try {
    const response = await protectedHttp.post(
      `/${Paths.Tracking.tracking}`,
      body,
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const postUserSessionTracking = async (body, setErrorStatus) => {
  try {
    const response = await protectedHttp.post(
      `/${Paths.Tracking.userSession}`,
      {
        userId: body.userId,
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};
