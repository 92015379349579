import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../storage/context';
import CandidateSearchForm from '../../forms/CandidateSearchForm';
import DontShowForm from '../../forms/DontShowForm';
import { getCandidatesSearch, getCandidatesCount } from '../../../api/services/Candidates';
import { PATHS } from '../../../utils/constants';
import { createQueryFilterList } from '../../../utils/helpers';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import ScreenResolver from '../../common/ScreenResolver';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import Button from '../../common/ui/Buttons/Button';
import Button34 from '../../common/ui/Buttons/Button34';
import BackButton from '../../common/ui/Buttons/BackButton';
import './index.scss';

const CandidatesSearchPage = () => {
  const [
    {
      helpers = {},
      candidateSearchList = [],
      candidateSearchParams = null,
      candidateSearchParamsDisplayed = null,
    },
    dispatch,
  ] = React.useContext(AppContext);
  const [candidatesCount, setCandidatesCount] = React.useState(0);

  const intl = useIntl();
  const navigate = useNavigate();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const formElement = React.useRef(null);
  const pageContentElement = React.useRef(null);

  const showSearchWarningModal = JSON.parse(sessionStorage.getItem('showSearchWarningModal')) ?? true;

  const initialValues = {
    mainCategory: '',
    subcategory: '',
    tools: [null],
    skills: [],
    allSkills: [],
  };

  const resetSearch = () => {
    if (!formElement.current) {
      return;
    }
    const setValues = formElement.current.setValues;
    setValues(initialValues);
    setCandidatesCount(0);
    dispatch({
      type: 'setCandidateSearchList',
      candidateSearchList: [],
    });
    dispatch({ type: 'nullCandidateSearchParams' });
    dispatch({
      type: 'setCandidateSearchParamsDisplayed',
      candidateSearchParamsDisplayed: initialValues,
    });
  };

  const evaluateDontShowForm = (values, { setSubmitting }) => {
    dispatch({
      type: 'nullModalData',
    });
    if (values?.dontShow) {
      sessionStorage.setItem('showSearchWarningModal', false);
    }
    
    setSubmitting(false);
  };

  const searchCandidates = async () => {
    try {
      if (
        candidateSearchParams?.subcategory ||
        candidateSearchParams?.tools.find(tool => !!tool) ||
        candidateSearchParams?.skills.find(skill => skill.isChecked) ||
        candidateSearchParams?.allSkills.length > 0
      ) {
        loadingLayer.show();
        const candidatesData = await getCandidatesSearch(
          createQueryFilterList(candidateSearchParams),
          setError,
        );
        if (candidatesData?.length) {
          dispatch({
            type: 'setCandidateSearchList',
            candidateSearchList: candidatesData,
          });
          navigate(PATHS.searchResult);
        }
      } else {
        dispatch({
          type: 'setCandidateSearchList',
          candidateSearchList: [],
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }
  };

  const searchCandidatesCount = async (values, { setSubmitting }) => {
    const searchData = {
      mainCategory: values.mainCategory,
      subcategory: values.subcategory,
      tools: values.tools,
      skills: values.skills,
      allSkills: values.allSkills,
    };

    dispatch({
      type: 'setCandidateSearchParamsDisplayed',
      candidateSearchParamsDisplayed: searchData,
    });

    try {
      if (
        searchData.subcategory ||
        searchData.tools.find(tool => !!tool) ||
        searchData.skills.find(skill => skill.isChecked) ||
        searchData.allSkills.length > 0
      ) {
        loadingLayer.show();
        const response = await getCandidatesCount(
          createQueryFilterList(searchData),
          setError,
        );
        if (response?.candidateCount >= 1) {
          setCandidatesCount(response.candidateCount);
          dispatch({
            type: 'setCandidateSearchParams',
            candidateSearchParams: searchData,
          });
        } else {
          if (showSearchWarningModal) {
            dispatch({
              type: 'setModalData',
              modalData: {
                title: intl.messages['modal.title.searchNoCandidates'],
                description: intl.messages['modal.description.searchNoCandidates'],
                form: <DontShowForm onSubmit={evaluateDontShowForm}/>,
              }
            });
          }
        }
      } else {
        setCandidatesCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }
    
    setSubmitting(false);
  };

  React.useEffect(() => {
    if (!candidateSearchParams) {
      dispatch({
        type: 'setCandidateSearchList',
        candidateSearchList: [],
      });
    }
  }, [candidateSearchParams, dispatch]);

  React.useEffect(() => {
    if (candidateSearchList?.length) {
      setCandidatesCount(candidateSearchList.length);
    }
  }, [candidateSearchList]);

  return (
    <div className="candidates-search-page">
      <div className="candidates-search-page__container">
        <TopMenuLayerMobile />
        <div className="candidates-search-page__mobile-title">
          {intl.messages['candidateSearch.titlePart1']}
          {intl.messages['candidateSearch.titlePart2']}
        </div>
        <div className="candidates-search-page__header">
          <h1 className="candidates-search-page__title">
            <span
              className="candidates-search-page__title-part-1"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.titlePart1'] }}
            ></span>
            <span className="candidates-search-page__title-space">{'\u00A0'}</span>
            <span
              className="candidates-search-page__title-part-2"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.titlePart2'] }}
            ></span>
          </h1>
        </div>
        <div className="candidates-search-page__content" ref={pageContentElement}>
          <CandidateSearchForm
            onSubmit={searchCandidatesCount}
            initialValues={initialValues}
            candidateSearchParamsDisplayed={candidateSearchParamsDisplayed}
            helpers={helpers}
            pageContentElement={pageContentElement.current}
            formElement={formElement}
          />
        </div>
        <div className="candidates-search-page__footer">
          <div className="candidates-search-page__button-container">
            <ScreenResolver
              large={BREAKPOINTS.md}
              desktop={
                <Button
                  className="candidates-search-page__button-results"
                  label={`
                    ${intl.messages['candidateSearch.resultsButtonPart1']}
                    \u00A0${candidatesCount}\u00A0
                    ${
                      candidatesCount === 1 ?
                      intl.messages['candidateSearch.resultsButtonPart2.singular'] :
                      intl.messages['candidateSearch.resultsButtonPart2.plural']
                    }`}
                  handleClick={searchCandidates}
                  disabled={!candidatesCount}
                />
              }
              mobile={
                <Button34
                  className="candidates-search-page__button-results"
                  label={`
                    ${intl.messages['candidateSearch.resultsButtonPart1']}
                    \u00A0${candidatesCount}\u00A0
                    ${
                      candidatesCount === 1 ?
                      intl.messages['candidateSearch.resultsButtonPart2.singular'] :
                      intl.messages['candidateSearch.resultsButtonPart2.plural']
                    }`}
                  handleClick={searchCandidates}
                  disabled={!candidatesCount}
                />
              }
            />
            <h4
              className="candidates-search-page__reset"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.reset'] }}
              onClick={resetSearch}
            ></h4>
          </div>
        </div>
      </div>
      <BackButton />
    </div>
  );
};

export default CandidatesSearchPage;
