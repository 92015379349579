import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const LevelSelectorItem = props => {
  const { level, active, onClick } = props;

  return (
    <div
      className="level-selector-item"
      onClick={onClick}
    >
      <div className={classnames(
        'level-selector-item__indicator',
        active && '-active',
      )} />
      <h5 className="level-selector-item__level">{level}</h5>
    </div>
  );
};

export default LevelSelectorItem;
