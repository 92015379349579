import React from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../storage/context';
import MyCandidateStartForm from '../../../forms/MyCandidateStartForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss'

const MyCandidatePageStart = props => {
  const dispatch = React.useContext(AppContext)[1];
  const { startCandidateCreation } = props;
  const intl = useIntl();

  React.useEffect(() => {
    dispatch({
      type: 'setMyCandidate',
      myCandidate: null,
    });
  }, [dispatch]);

  return (
    <div className="my-candidate-page-start">
      <div className="my-candidate-page-start__avatar-container">
        <div className="my-candidate-page-start__avatar">
          <FontAwesomeIcon icon={faUser} className="my-candidate-page-start__avatar-image" />
        </div>
      </div>
      <h2 className="my-candidate-page-start__title">
        {intl.messages['myCandidate.start.title']}
        <span className="my-candidate-page-start__title -secondary">&nbsp;{intl.messages['myCandidate.start.candidate']}</span>
      </h2>
      <p
        className="my-candidate-page-start__description"
        dangerouslySetInnerHTML={{ __html: intl.messages['myCandidate.start.description']}}
      ></p>
      <MyCandidateStartForm onSubmit={startCandidateCreation} />
    </div>
  );
};

export default MyCandidatePageStart;
