import React from 'react';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL, LANGUAGE, LANGUAGES } from '../../../utils/constants';
import Button34 from '../ui/Buttons/Button34';
import './index.scss';

const CandidateCard = (props) => {
  const { helpers = {}, candidateSearchParams = null } = React.useContext(AppContext)[0];
  const { className, candidate, navigateToCandidate } = props;

  const intl = useIntl();
  const { tools, skills, languages } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const searchedToolIds = candidateSearchParams?.tools.filter(tool => !!tool).map(tool => tool.value) || [];
  const skillIds = candidateSearchParams?.skills.filter(skill => skill.isChecked).map(skill => skill.groupId) || [];
  const allSkillIds = candidateSearchParams?.allSkills.map(skill => skill.value) || [];
  const searchedSkillIds = [...new Set(skillIds.concat(allSkillIds))];

  const sortTools = originalTools => {
    return originalTools
      .map(tool => tools?.find(toolEntity => toolEntity.id === tool.toolId))
      .sort((tool1, tool2) => tool2.level - tool1.level)
      .sort((tool1, tool2) => {
        if (searchedToolIds.includes(tool2.groupId) && !searchedToolIds.includes(tool1.groupId)) {
          return 1;
        }
        if (!searchedToolIds.includes(tool2.groupId) && searchedToolIds.includes(tool1.groupId)) {
          return -1;
        }
        return 0;
      });
  };

  const sortSkills = originalSkillIds => {
    return originalSkillIds
      .map(skillId => skills?.find(skill => skill.id === skillId))
      .sort((skill1, skill2) => skill2.level - skill1.level)
      .sort((skill1, skill2) => {
        if (searchedSkillIds.includes(skill2.groupId) && !searchedSkillIds.includes(skill1.groupId)) {
          return 1;
        }
        if (!searchedSkillIds.includes(skill2.groupId) && searchedSkillIds.includes(skill1.groupId)) {
          return -1;
        }
        return 0;
      });
  };

  return (
    <div className={classnames(
      'candidate-card',
      className && className,
    )}>
      <div className="candidate-card__avatar-container">
        {candidate.avatar ? (
          <div className="candidate-card__avatar">
            <img className="candidate-card__avatar-image" src={`${API_BASE_URL}${candidate.avatar}`} alt={candidate.profileName} />
          </div>
        ) : (
          <div className="candidate-card__avatar-placeholder">
            <FontAwesomeIcon icon={faUser} className="candidate-card__avatar-placeholder-image" />
          </div>
        )}
      </div>
      <p
        className="candidate-card__profile-number"
        dangerouslySetInnerHTML={{ __html: candidate.profileNumber }}
      ></p>
      <p
        className="candidate-card__profile-title"
        dangerouslySetInnerHTML={{ __html: candidate[selectedLanguageCode].profileTitle }}
      ></p>
      <p
        className="candidate-card__profile-description"
        dangerouslySetInnerHTML={{ __html: candidate[selectedLanguageCode].profileDescription }}
      ></p>
      <div className="candidate-card__space" />
      <div className="candidate-card__tools">
        {sortTools(candidate.tools)
          .map((tool, index) => (
            index < 2 && (
              <div key={tool.id} className="candidate-card__tool">
                <p
                  className="candidate-card__tool-name"
                  dangerouslySetInnerHTML={{ __html: tool.name }}
                ></p>
                {[1, 2, 3, 4, 5].map((position, i) => (
                  <div
                    key={position}
                    className={classnames(
                      'candidate-card__tool-level-indicator',
                      i >= tool.level && '-empty',
                    )}
                  ></div>
                ))}
              </div>
            )
        ))}
      </div>
      <div className="candidate-card__skills">
        {sortSkills(candidate.skillIds)
          .map((skill, index) => (
            index < 3 && (
              <div key={skill.id} className="candidate-card__skill">
                <p
                  className="candidate-card__skill-name"
                  dangerouslySetInnerHTML={{ __html: skill[selectedLanguageCode].name }}
                ></p>
                {[1, 2, 3, 4, 5].map((position, i) => (
                  <div
                    key={position}
                    className={classnames(
                      'candidate-card__skill-level-indicator',
                      i >= skill.level && '-empty',
                    )}
                  ></div>
                ))}
              </div>
            )
        ))}
      </div>
      <div className="candidate-card__languages">
        {candidate.languageIds.map((languageId, index) => (
          index < 6 && (
            <div key={languageId} className="candidate-card__language">
              <img
                className="candidate-card__language-icon"
                src={
                  languages
                  ? `${API_BASE_URL}${languages?.find(language => language.id === languageId)?.icon}`
                  : null
                }
                alt="lang-icon"
                title={languages?.find(language => language.id === languageId)?.[selectedLanguageCode].name}
              />
            </div>
          )
        ))}
      </div>
      <div className="candidate-card__button-container">
        <Button34
          className='candidate-card__button -primary-dark'
          label={intl.messages['common.goTo']}
          handleClick={() => navigateToCandidate(candidate.id)}
        />
      </div>
    </div>
  );
};

export default CandidateCard;
