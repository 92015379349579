import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PATHS } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useMyCandidates from '../../../utils/hooks/useMyCandidates';
import useResolveScreen from '../../../utils/hooks/useResolveScreen';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import BackButton from '../../common/ui/Buttons/BackButton';
import Button34 from '../../common/ui/Buttons/Button34';
import MyCandidateListItem from '../../common/MyCandidateListItem';
import PromptModal from '../../common/PromptModal';
import './index.scss';

const PROMPT_DEFAULT_VALUES = {
  isVisible: false,
  data: null,
};

const ProfileMyCandidatesPage = () => {
  const [promptAttributes, setPromptAttributes] = React.useState(PROMPT_DEFAULT_VALUES);

  const navigate = useNavigate();
  const intl = useIntl();
  const myCandidates = useMyCandidates();
  const screen = useResolveScreen(BREAKPOINTS.md);

  const deleteMyCandidate = async (candidateId) => {
    await myCandidates.delete(candidateId);
    setPromptAttributes(PROMPT_DEFAULT_VALUES);
  };

  React.useEffect(() => {
    if (screen) {
      if (screen.isMobile === false) {
        navigate(PATHS.profile);
      }
    }
  }, [navigate, screen]);

  return (
    <>
      <div className="profile-my-candidates-page">
        <div className="profile-my-candidates-page__container">
          <TopMenuLayerMobile />
          <div className="profile-my-candidates-page__title">
            {intl.messages['profile.menu.myCandidate']}
          </div>
          <div className="profile-my-candidates-page__content">
            {myCandidates.list?.length ? (
              myCandidates.list.map((candidate, i) => (
                <MyCandidateListItem
                  key={i}
                  myCandidate={candidate}
                  deleteMyCandidate={() => setPromptAttributes({
                    ...promptAttributes,
                    isVisible: true,
                    data: { myCandidateId: candidate.id },
                  })}
                />
              ))) : (
                <p
                  className="profile-my-candidates-page__fallback-message"
                  dangerouslySetInnerHTML={{ __html: intl.messages['profile.content.noMyCandidates'] }}
                ></p>
              )
            }
          </div>
          <div className="profile-my-candidates-page__bottom-section">
            <div className="profile-my-candidates-page__button-container">
              <Button34
                className="profile-my-candidates-page__button"
                label={intl.messages['profile.menu.title']}
                handleClick={() => navigate(PATHS.profile)}
              />
            </div>
            <BackButton className="profile-my-candidates-page__back-button" />
          </div>
        </div>
      </div>
      <PromptModal
        isVisible={promptAttributes?.isVisible}
        title={intl.messages['common.warning']}
        description={intl.messages['modal.reallyDelete']}
        onAccept={() => {
          deleteMyCandidate(promptAttributes.data?.myCandidateId);
          setPromptAttributes(PROMPT_DEFAULT_VALUES);
        }}
        onCancel={() => setPromptAttributes(PROMPT_DEFAULT_VALUES)}
      />
    </>
  );
};

export default ProfileMyCandidatesPage;
