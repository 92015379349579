export default class Paths {
  static Translation = class {
    static translations = 'intl';
  };

  static Auth = class {
    static login = 'auth/local';
    static register = 'auth/local/register';
    static forgotPassword = 'auth/forgot-password';
    static resetPassword = 'auth/reset-password';
    static resendVerificationMail = 'auth/send-email-confirmation'
  };

  static Category = class {
    static categories = 'categories';
  };

  static User = class {
    static savedCandidate = 'saved-candidates';
    static contactCandidate = 'wanted-candidates';
    static checkEmail = 'user-list/check-email';
    static dreamCandidate = 'user-dream-candidates';
    static me = 'users/me';
  };

  static Candidate = class {
    static candidates = 'candidate-list';
    static candidatesSearch = 'candidate-list-search';
    static candidate = 'candidate';
    static savedCandidates = 'user-saved-candidates';
  };

  static Case = class {
    static cases = 'cases';
  };

  static Faq = class {
    static faq = 'faq';
  };

  static Tracking = class {
    static tracking = 'tracking/events';
    static userSession = 'tracking/user-sessions';
  };

  static IntroVideo = class {
    static introVideo = 'intro-video';
  }

  static Helper = class {
    static mainCategories = 'helpers/main-categories';
    static subcategories = 'helpers/subcategories';
    static tools = 'helpers/tools';
    static skills = 'helpers/skills';
    static languages = 'helpers/languages';
    static caseCategories = 'helpers/case-categories';
    static cases = 'helpers/cases';
    static eventTypes = 'helpers/event-types';
  };
}
