import React from 'react';
import { useIntl } from 'react-intl';
import useMyCandidates from '../../../../../utils/hooks/useMyCandidates';
import MyCandidateListItem from '../../../../common/MyCandidateListItem';
import PromptModal from '../../../../common/PromptModal';
import './index.scss';

const PROMPT_DEFAULT_VALUES = {
  isVisible: false,
  data: null,
};

const ProfilePageMyCandidates = () => {
  const [promptAttributes, setPromptAttributes] = React.useState(PROMPT_DEFAULT_VALUES);

  const intl = useIntl();
  const myCandidates = useMyCandidates();

  const deleteMyCandidate = async (candidateId) => {
    await myCandidates.delete(candidateId);
    setPromptAttributes(PROMPT_DEFAULT_VALUES);
  };

  return (
    <>
      <div className="profile-page-my-candidates">
        {myCandidates.list?.length ? (
          myCandidates.list?.map((candidate, i) => (
            <MyCandidateListItem
              key={i}
              myCandidate={candidate}
              deleteMyCandidate={() => setPromptAttributes({
                ...promptAttributes,
                isVisible: true,
                data: { myCandidateId: candidate.id },
              })}
            />
          ))) : (
            <p
              className="profile-page-my-candidates__fallback-message"
              dangerouslySetInnerHTML={{ __html: intl.messages['profile.content.noMyCandidates'] }}
            ></p>
          )}
      </div>
      <PromptModal
        isVisible={promptAttributes?.isVisible}
        title={intl.messages['common.warning']}
        description={intl.messages['modal.reallyDelete']}
        onAccept={() => {
          deleteMyCandidate(promptAttributes.data?.myCandidateId);
          setPromptAttributes(PROMPT_DEFAULT_VALUES);
        }}
        onCancel={() => setPromptAttributes(PROMPT_DEFAULT_VALUES)}
      />
    </>
  );
};

export default ProfilePageMyCandidates;
