import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import LanguageSelectMenu from '../../common/LanguageSelectMenu';
import { PATHS } from '../../../utils/constants';
import Button from '../../common/ui/Buttons/Button';
import BackButton from '../../common/ui/Buttons/BackButton';
import './index.scss';

const PrivacyPolicyPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-page__container">
        <LanguageSelectMenu
          className="privacy-policy-page__language-select-menu"
        />
        <h3 className="privacy-policy-page__title">
          {intl.messages['privacyPolicy.title']}
        </h3>
        <div className="privacy-policy-page__content">
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.intro']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.intro'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.cookies']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.cookies'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.personalData']}
          </h4>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.general']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.general'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.security']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.security'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.purpose']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.purpose'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.storagePeriod']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.storagePeriod'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.infoDisclosure']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.infoDisclosure'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.insights']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.insights'] }}
          ></p>
          <h4 className="privacy-policy-page__subtitle">
            {intl.messages['privacyPolicy.subtitle.publisher']}
          </h4>
          <p
            className="privacy-policy-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['privacyPolicy.description.publisher'] }}
          ></p>
        </div>
        <Button
            className='privacy-policy-page__button -secondary'
            label={intl.messages['common.return']}
            handleClick={() => navigate(PATHS.register)}
        />
      </div>
      <BackButton />
    </div>
  );
};

export default PrivacyPolicyPage;
