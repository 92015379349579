import React from 'react';
import { deleteSavedCandidate, getSavedCandidates } from '../../api/services/Candidates';
import useLoadingLayer from './useLoadingLayer';
import useSetError from './useSetError';

const useSavedCandidates = () => {
  const [savedCandidateList, setSavedCandidateList] = React.useState([]);

  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const removeSavedCandidate = React.useCallback(async candidateId => {
    try {
      loadingLayer.show();
      await deleteSavedCandidate(candidateId, setError);
      setSavedCandidateList(await getSavedCandidates(setError));
    } catch (error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }
  }, [loadingLayer, setError]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        loadingLayer.show();
        setSavedCandidateList(await getSavedCandidates(setError));
      } catch (error) {
        console.error(error);
      } finally {
        loadingLayer.hide();
      }
    };

    fetchData();
  }, [loadingLayer, setError]);

  return React.useMemo(() => (
    { list: savedCandidateList, remove: removeSavedCandidate }
  ), [savedCandidateList, removeSavedCandidate]);
};

export default useSavedCandidates;
