import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { FieldArray, Formik } from 'formik';
import * as classnames from 'classnames';
import { singleValueSelect } from '../../../utils/selectFieldStyles/singleValueSelect';
import { multiValueSelect } from '../../../utils/selectFieldStyles/multiValueSelect';
import {
  createMainCategoriesSelectOptions,
  createSubcategoriesSelectOptions,
  createToolsSelectOptionsWithoutLevels,
  createSkillsWithoutLevels,
  createSkillsSelectOptionsWithoutLevelsAlphabetic,
} from '../../../utils/helpers';
import { LANGUAGE, LANGUAGES } from '../../../utils/constants';
import SelectField from '../../common/ui/SelectField';
import Slider from '../../common/ui/Slider';
import ToTopButton from '../../common/ui/Buttons/ToTopButton';
import './index.scss';

const CandidateSearchForm = (props) => {
  const {
    onSubmit,
    initialValues,
    candidateSearchParamsDisplayed,
    helpers,
    pageContentElement,
    formElement,
  } = props;

  const intl = useIntl();
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const onChangeTools = (selectedTool, i, values, setValues) => {
    const tools = [...values.tools];

    tools[i] = selectedTool;
    if (selectedTool === null) {
      if (i !== tools.length - 1) {
        tools.splice(i, 1);
      }
    } else if (i === values.tools.length - 1) {
      tools.push(null);
    }

    setValues({ ...values, tools });
  };

  const resetSearch = (setValues) => {
    setValues(initialValues);
  };

  const scrollToTop = () => {
    pageContentElement.scroll(0, 0);
  };

  return (
    <Formik
      initialValues={candidateSearchParamsDisplayed || initialValues}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      innerRef={formElement}
    >
      {({ values, handleBlur, handleSubmit, setFieldValue, setValues }) => (
        <form className="candidate-search__form" onSubmit={handleSubmit}>
          <section className="candidate-search__section-categories">
            <h3
              className="candidate-search__section-title"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.categories.title'] }}
            ></h3>
            <SelectField
              className="candidate-search__select"
              name="mainCategory"
              placeholder={intl.messages['candidateSearch.categories.industry']}
              onChange={selectedCategory => {
                setFieldValue('mainCategory', selectedCategory);
                setFieldValue('subcategory', initialValues.subcategory);
              }}
              value={values.mainCategory}
              styles={singleValueSelect}
              isClearable={true}
              isSearchable={true}
              options={createMainCategoriesSelectOptions(helpers.mainCategories, selectedLanguageCode)}
              filterOption={(option, input) =>
                option.label.toLowerCase().startsWith(input.toLowerCase())
              }
              onBlur={handleBlur}
            />
            <SelectField
              className="candidate-search__select"
              name="subcategory"
              placeholder={intl.messages['candidateSearch.categories.specialization']}
              onChange={selectedCategory => {
                setFieldValue('subcategory', selectedCategory);
                setFieldValue('skills', createSkillsWithoutLevels(helpers.skills, selectedCategory));
                handleSubmit();
              }}
              value={values.subcategory}
              styles={singleValueSelect}
              isClearable={true}
              isSearchable={true}
              options={createSubcategoriesSelectOptions(
                helpers.subcategories,
                helpers.mainCategories?.find(category => category.id === values.mainCategory?.value) || null,
                selectedLanguageCode,
              )}
              filterOption={(option, input) =>
                option.label.toLowerCase().startsWith(input.toLowerCase())
              }
              onBlur={handleBlur}
            />
          </section>
          <section className="candidate-search__section-tools">
            <h3
              className="candidate-search__section-title"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.tools.title'] }}
            ></h3>
            <FieldArray name="tools">
              {() => (values.tools.map((tool, i) => (
                <Fragment key={i}>
                  <SelectField
                    className="candidate-search__select"
                    name={`tools[${i}]`}
                    placeholder={intl.messages['common.select']}
                    onChange={selectedTool => {
                      onChangeTools(selectedTool, i, values, setValues);
                      handleSubmit();
                    }}
                    value={values.tools[i]}
                    styles={singleValueSelect}
                    isClearable={true}
                    isSearchable={true}
                    options={createToolsSelectOptionsWithoutLevels(helpers.tools, values)}
                    noOptionsMessage={() => intl.messages['common.noOptions']}
                    filterOption={(option, input) =>
                      option.label.toLowerCase().startsWith(input.toLowerCase())
                    }
                    onBlur={handleBlur}
                  />
                </Fragment>
              )))}
            </FieldArray>
          </section>
          <section className="candidate-search__section-skills">
            {values.subcategory ? (
              <>
                {values.skills.length ? (
                  <>
                    <h3
                      className="candidate-search__section-title"
                      dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.skills.title'] }}
                    ></h3>
                    <div className="candidate-search__skills-container">
                      <FieldArray name="skills">
                        {() => (values.skills.map((skill, i) =>  (
                          <Fragment key={i}>
                            <Slider
                              className="candidate-search__slider"
                              title={skill[selectedLanguageCode].name}
                              name={`skills[${i}]`}
                              isChecked={values.skills[i].isChecked}
                              onChange={(event => {
                                const isChecked = event?.target?.checked;
                                setFieldValue(
                                  `skills[${i}]`,
                                  {
                                    ...values.skills[i],
                                    isChecked: isChecked,
                                  },
                                );
                                handleSubmit();
                              })}
                            />
                          </Fragment>
                        )))}
                      </FieldArray>
                    </div>
                  </>
                ) : (
                  <h3
                    className="candidate-search__section-title"
                    dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.skills.noSkills'] }}
                  ></h3>
                )}
              </>
            ) : (
              <h3
                className="candidate-search__section-title"
                dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.skills.noSubcategory'] }}
              ></h3>
            )}
            <SelectField
              className="candidate-search__select"
              isMulti
              name="allSkills"
              placeholder={intl.messages['common.select']}
              onChange={selectedSkills => {
                setFieldValue('allSkills', selectedSkills);
                handleSubmit();
              }}
              value={values.allSkills}
              styles={multiValueSelect}
              isClearable={true}
              isSearchable={true}
              options={createSkillsSelectOptionsWithoutLevelsAlphabetic(helpers.skills, selectedLanguageCode)}
              noOptionsMessage={() => intl.messages['common.noOptions']}
              filterOption={(option, input) =>
                option.label.toLowerCase().startsWith(input.toLowerCase())
              }
              onBlur={handleBlur}
            />
          </section>
          <section className="candidate-search__section-bottom">
            <h4
              className="candidate-search__reset"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.reset'] }}
              onClick={() => resetSearch(setValues)}
            ></h4>
            <h3
              className="candidate-search__section-title -bottom"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.bottom.title'] }}
            ></h3>
            <p
              className="candidate-search__phone"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.bottom.phone'] }}
            ></p>
            <ToTopButton
              className={classnames(
                'candidate-search__button-to-top',
              )}
              handleClick={scrollToTop}
            />
            <p
              className="candidate-search__text-to-top"
              dangerouslySetInnerHTML={{ __html: intl.messages['candidate.toTop'] }}
              onClick={scrollToTop}
            ></p>
          </section>
        </form>
      )}
    </Formik>
  );
};

export default CandidateSearchForm;
