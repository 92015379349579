import React from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { LANGUAGE, LANGUAGES, PATHS } from '../../../utils/constants';
import { isInCandidatePageViewport } from '../../../utils/helpers';
import useSetError from '../../../utils/hooks/useSetError';
import useSwipe from '../../../utils/hooks/useSwipe';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import { getCandidate } from '../../../api/services/Candidates';
import ScreenResolver from '../../common/ScreenResolver';
import BackButton from '../../common/ui/Buttons/BackButton';
import CandidateDetails from '../../common/CandidateDetails';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import CandidateDetailsMobile from '../../common/CandidateDetailsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const CandidateByCasePage = () => {
  const { helpers = {}, candidateCaseList = [] } = React.useContext(AppContext)[0];
  const [candidate, setCandidate] = React.useState();
  const candidateIndex = candidateCaseList.length
    ? candidateCaseList.indexOf(candidateCaseList.find(
        listedCandidate => listedCandidate.id === candidate?.id
      ))
    : null;
  const pageContentElement = React.useRef(null);

  const { candidateId } = useParams();
  const searchParams = useSearchParams()[0];
  const setError = useSetError();

  const caseSlug = searchParams.get('caseSlug');
  const { cases } = helpers;

  const navigate = useNavigate();
  const { hash } = useLocation();
  const loadingLayer = useLoadingLayer();

  const selectedLanguageCode = React.useMemo(() => {
    return localStorage.getItem(LANGUAGE) || LANGUAGES.DK;
  }, []);

  const sectionElements = {
    profile: React.useRef(null),
    references: React.useRef(null),
    skills: React.useRef(null),
    education: React.useRef(null),
    certifications: React.useRef(null),
    bottom: React.useRef(null),
  };

  const currentCaseName = React.useMemo(() => {
    return cases?.find(caseItem => caseItem.slug === caseSlug)?.[selectedLanguageCode].name;
  }, [cases, caseSlug, selectedLanguageCode]);

  const getFirstVisibleElementKey = elementRefs => {
    let firstVisibleElementKey;

    for (let i = 0; i < Object.keys(elementRefs).length; i++) {
      const elementKey = Object.keys(elementRefs)[i];

      if (elementKey) {
        const element = elementRefs[elementKey];

        if (element.current && isInCandidatePageViewport(element.current)) {
          firstVisibleElementKey = elementKey;
          break;
        }
      }
    }

    return firstVisibleElementKey || null;
  };

  const goToNextCandidate = () => {
    if (candidateIndex === null || candidateIndex >= candidateCaseList?.length - 1) {
      return;
    }

    const elementKeyToShow = getFirstVisibleElementKey(sectionElements);
    const path = `${PATHS.candidateByCase}/${candidateCaseList[candidateIndex + 1]?.id}`;

    const params = new URLSearchParams();
    params.append('caseSlug', caseSlug);

    if (!elementKeyToShow) {
      navigate(`${path}?${params}`);
    } else {
      navigate(`${path}?${params}#${elementKeyToShow}`);
    }
  };

  const goToPreviousCandidate = () => {
    if (candidateIndex === null || candidateIndex === 0) {
      return;
    }

    const elementKeyToShow = getFirstVisibleElementKey(sectionElements);
    const path = `${PATHS.candidateByCase}/${candidateCaseList[candidateIndex - 1]?.id}`;

    const params = new URLSearchParams();
    params.append('caseSlug', caseSlug);

    if (!elementKeyToShow) {
      navigate(`${path}?${params}`);
    } else {
      navigate(`${path}?${params}#${elementKeyToShow}`);
    }
  };

  const swipe = useSwipe({
    handleSwipeLeft: goToNextCandidate,
    handleSwipeRight: goToPreviousCandidate,
  });

  React.useEffect(() => {
    const fetchData = async () => {  
      try {
        if (candidateId) {
          loadingLayer.show();
          setCandidate(await getCandidate(candidateId, setError));
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingLayer.hide();
      }
    };

    fetchData();
  }, [loadingLayer, setError, candidateId]);

  React.useEffect(() => {
    if (hash) {
      const element = sectionElements[hash.replace('#', '')];
      if (element?.current) {
        element.current.scrollIntoView(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate]);

  return (
    <div className="candidate-by-case-page">
      <div className="candidate-by-case-page__container">
        <TopMenuLayerMobile />
        <div className="candidate-by-case-page__mobile-title">
          {`${currentCaseName} / ${candidate?.profileNumber || ''}`}
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <>
              <div className="candidate-by-case-page__header">
                <h1 className="candidate-by-case-page__title">
                  <span
                    className="candidate-by-case-page__profile-number"
                    dangerouslySetInnerHTML={{ __html: candidate?.profileNumber }}
                  ></span>
                </h1>
              </div>
              <div className="candidate-by-case-page__content"  ref={pageContentElement}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className={classnames(
                    'candidate-by-case-page__chevron',
                    '-left',
                    candidateCaseList.length <= 1 && '-disabled',
                    candidateIndex !== null && candidateIndex === 0 && '-disabled',
                  )}
                  onClick={goToPreviousCandidate}
                />
                <div className="candidate-by-case-page__candidate">
                  <CandidateDetails
                    candidate={candidate}
                    pageContentElement={pageContentElement.current}
                    sectionElements={sectionElements}
                  />
                </div>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={classnames(
                    'candidate-by-case-page__chevron',
                    '-right',
                    candidateCaseList.length <= 1 && '-disabled',
                    candidateIndex !== null && candidateIndex >= (candidateCaseList.length - 1) && '-disabled',
                  )}
                  onClick={goToNextCandidate}
                />
              </div>
              <div className="candidate-by-case-page__footer"></div>
            </>
          }
          mobile={
            <div className="candidate-by-case-page__content">
              <div
                className="candidate-by-case-page__candidate"
                onTouchStart={swipe.onTouchStart}
                onTouchMove={swipe.onTouchMove}
                onTouchEnd={swipe.onTouchEnd}
              >
                <CandidateDetailsMobile
                  candidate={candidate}
                  sectionElements={sectionElements}
                  previousButton={
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className={classnames(
                        'candidate-by-case-page__chevron',
                        '-left',
                        candidateCaseList.length <= 1 && '-disabled',
                        candidateIndex !== null && candidateIndex === 0 && '-disabled',
                      )}
                      onClick={goToPreviousCandidate}
                    />
                  }
                  nextButton={
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={classnames(
                        'candidate-by-case-page__chevron',
                        '-right',
                        candidateCaseList.length <= 1 && '-disabled',
                        candidateIndex !== null && candidateIndex >= (candidateCaseList.length - 1) && '-disabled',
                      )}
                      onClick={goToNextCandidate}
                    />
                  }
                />
              </div>
            </div>
          }
        />
        <div className="candidate-by-case-page__bottom-section">
          <BackButton className="candidate-by-case-page__back-button" />
        </div>
      </div>
    </div>
  );
};

export default CandidateByCasePage;
