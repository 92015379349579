import React from 'react';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { LANGUAGE, LANGUAGES } from '../../../utils/constants';
import flagDk from '../../../assets/images/flags/dk.png';
import flagEn from '../../../assets/images/flags/en.png';
import './index.scss';

const LanguageSelectMenu = props => {
  const [{ selectedLanugage = '' }, dispatch] = React.useContext(AppContext);

  const { className } = props;

  const selectLanguage = (lang = '') => {
    if (lang !== selectedLanugage) {
      localStorage.setItem(LANGUAGE, lang);
      dispatch({ type: 'setSelectedLanguage', selected: lang });
    }
  };

  return (
    <div className={classnames(
      'language-select-menu',
      className && className,
    )}>
      <div className="language-select-menu__container">
        <div
          className="language-select-menu__flag"
          onClick={() => selectLanguage(LANGUAGES.DK)}
        >
          <img className="language-select-menu__flag-image" src={flagDk} alt="flag-dk" />
        </div>
        <div
          className="language-select-menu__flag"
          onClick={() => selectLanguage(LANGUAGES.EN)}
        >
          <img className="language-select-menu__flag-image" src={flagEn} alt="flag-en" />
        </div>
      </div>
    </div>
  );
};

export default LanguageSelectMenu;
