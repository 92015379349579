import React from 'react';
import * as classnames from 'classnames';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../ScreenResolver';
import CandidateCard from '../CandidateCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const NUMBER_OF_CANDIDATES = 4;
const NUMBER_OF_CANDIDATES_MEDIUM = 2;

const CandidateDisplay = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { candidates, navigateToCandidate } = props;

  const maxPages = Math.ceil(candidates.length / NUMBER_OF_CANDIDATES);

  return (
    <div className="candidate-display">
      <FontAwesomeIcon
        icon={faChevronLeft}
        className={classnames(
          'candidate-display__chevron',
          currentPage <= 1 && '-disabled',
        )}
        onClick={() => setCurrentPage(currentPage - 1)}
      />
      <div className="candidate-display__card-container">
        <ScreenResolver
          large={BREAKPOINTS.lg}
          desktop={
            candidates.map((candidate, index) => (
              (Math.ceil((index + 1) / NUMBER_OF_CANDIDATES) === currentPage) ? (
                <CandidateCard
                  key={candidate.id}
                  className="candidate-display__card"
                  candidate={candidate}
                  navigateToCandidate={navigateToCandidate}
                />
              ) : (
                null
              )
            ))
          }
          mobile={
            candidates.map((candidate, index) => (
              (Math.ceil((index + 1) / NUMBER_OF_CANDIDATES_MEDIUM) === currentPage) ? (
                <CandidateCard
                  key={candidate.id}
                  className="candidate-display__card"
                  candidate={candidate}
                  navigateToCandidate={navigateToCandidate}
                />
              ) : (
                null
              )
            ))
          }
        />
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        className={classnames(
          'candidate-display__chevron',
          currentPage >= maxPages && '-disabled',
        )}
        onClick={() => setCurrentPage(currentPage + 1)}
      />
    </div>
  );
};

export default CandidateDisplay;
