const setStorage = (state, { storage }) => {
  if (storage) {
    state.storage = storage;
    state.isSet = true;
  }
  return { ...state };
};

const setIntl = (state, { intl }) => {
  if (intl) {
    state.intl = intl;
  }
  return { ...state };
};

const setError = (state, { error }) => {
  if (error && error.text) {
    state.error = error;
  }
  return { ...state };
};

const setIsLoading = (state, { isLoading }) => {
  state.isLoading = isLoading;
  return { ...state };
};

const setModalData = (state, { modalData }) => {
  if (modalData) {
    state.modalData = modalData;
  }
  return { ...state };
};

const setGuestModalData = (state, { guestModalData }) => {
  if (guestModalData) {
    state.guestModalData = guestModalData;
  }
  return { ...state };
};

const setFullScreenPromptModalData = (state, { fullScreenPromptModalData }) => {
  if (fullScreenPromptModalData) {
    state.fullScreenPromptModalData = fullScreenPromptModalData;
  }
  return { ...state };
};

const setVideoModalData = (state, { videoModalData }) => {
  if (videoModalData) {
    state.videoModalData = videoModalData;
  }
  return { ...state };
};

const setToken = (state, { token }) => {
  if (token) {
    state.token = token;
  }
  return { ...state };
};

const setSelectedLanguage = (state, { selected }) => {
  if (selected) {
    state.selectedLanguage = selected;
  }
  return { ...state };
};

const setHelpers = (state, { helpers }) => {
  if (helpers) {
    state.helpers = helpers;
  }
  return { ...state };
};

const setUser = (state, { user }) => {
  if (user) {
    state.user = user;
  }
  return { ...state };
};

const setCandidateCategoryList = (state, { candidateCategoryList }) => {
  state.candidateCategoryList = candidateCategoryList;
  return { ...state };
};

const setCandidateSearchList = (state, { candidateSearchList }) => {
  state.candidateSearchList = candidateSearchList;
  return { ...state };
};

const setCandidateCaseList = (state, { candidateCaseList }) => {
  state.candidateCaseList = candidateCaseList;
  return { ...state };
};

const setCandidateSearchParams = (state, { candidateSearchParams }) => {
  if (candidateSearchParams) {
    state.candidateSearchParams = candidateSearchParams;
  }
  return { ...state };
}

const setCandidateSearchParamsDisplayed = (state, { candidateSearchParamsDisplayed }) => {
  if (candidateSearchParamsDisplayed) {
    state.candidateSearchParamsDisplayed = candidateSearchParamsDisplayed;
  }
  return { ...state };
}

const setMyCandidate = (state, { myCandidate }) => {
  state.myCandidate = myCandidate;
  return { ...state };
};

const setMyCandidateList = (state, { myCandidateList }) => {
  state.myCandidateList = myCandidateList;
  return { ...state };
};

const setFaqList = (state, { faqList }) => {
  state.faqList = faqList;
  return { ...state };
};

const nullError = (state) => {
  state.error = { text: '', code: 0 };
  return { ...state };
};

const nullModalData = (state) => {
  state.modalData = {};
  return { ...state };
};

const nullGuestModalData = (state) => {
  state.guestModalData = {};
  return { ...state };
};

const nullFullScreenPromptModalData = (state) => {
  state.fullScreenPromptModalData = {};
  return { ...state };
};

const nullVideoModalData = (state) => {
  state.videoModalData = {};
  return { ...state };
};

const nullToken = (state) => {
  state.token = '';
  nullError(state, {});
  return { ...state };
};

const nullUser = (state) => {
  state.user = {};
  return { ...state };
};

const nullCandidateSearchParams = (state) => {
  state.candidateSearchParams = null;
  return { ...state };
};

const nullCandidateSearchParamsDisplayed = (state) => {
  state.candidateSearchParams = null;
  return { ...state };
};

const actions = {
  setStorage,
  setIntl,
  setError,
  setIsLoading,
  setModalData,
  setGuestModalData,
  setFullScreenPromptModalData,
  setVideoModalData,
  setToken,
  setSelectedLanguage,
  setHelpers,
  setUser,
  setCandidateCategoryList,
  setCandidateSearchList,
  setCandidateCaseList,
  setCandidateSearchParams,
  setCandidateSearchParamsDisplayed,
  setMyCandidate,
  setMyCandidateList,
  setFaqList,
  nullError,
  nullModalData,
  nullGuestModalData,
  nullFullScreenPromptModalData,
  nullVideoModalData,
  nullToken,
  nullUser,
  nullCandidateSearchParams,
  nullCandidateSearchParamsDisplayed,
};

export const reducer = (state, action) => {
  if (action && action.type && actions[action.type]) {
    return actions[action.type](state, action);
  }
  return { ...state };
};
