import React from 'react';
import * as classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import LevelSelectorItem from '../LevelSelectorItem';
import './index.scss';

const LevelSelector = props => {
    const { value, onChange, className, } = props;
  
    return (
      <div className={classnames(
        'level-selector',
        className
      )}>
        <div className="level-selector__item-container">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(level => (
            <LevelSelectorItem
              key={level}
              level={level}
              active={level <= value}
              onClick={() => onChange(level)}
            />  
          ))}
        </div>
        
        <div
          className={classnames(
            'level-selector__empty-button-container',
            !value && '-invisible',
          )}
          onClick={() => onChange(0)}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="level-selector__empty-button"
          />
        </div>
      </div>
    );
};

export default LevelSelector;
