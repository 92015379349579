import React from 'react';
import * as classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const SlickNextArrow = props => {
  const { className, onClick } = props;
  return (
    <div
      className={classnames(
        'slick-next-arrow',
        className && className,
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        className={classnames(
          'slick-next-arrow__chevron',
        )}
      />
    </div>
  );
};

export default SlickNextArrow;
