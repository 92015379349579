import React from 'react';
import * as classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const CloseButton = (props) => {
	const {
		className = '',
		handleClick = () => { },
		disabled = false,
	} = props;

	return (
		<div
			className={classnames(
        'close-button', className,
        disabled && '-disabled',
      )}
			onClick={handleClick}
			disabled={disabled}
		>
      <FontAwesomeIcon icon={faX} className="close-button__icon" />
		</div>
	)
}

export default CloseButton;
