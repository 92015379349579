import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { profileEditSchema } from '../../../utils/validationSchemas';
import InputTextField from '../../common/ui/InputTextField';
import Button from '../../common/ui/Buttons/Button';
import './index.scss';

const ProfileEditForm = props => {
  const { onSubmit, initialValues } = props;

  const intl = useIntl();

  const ERROR_MESSAGES = {
    EMAIL_EMPTY: intl.messages['error.emailEmpty'],
    EMAIL_FORMAT: intl.messages['error.emailInvalid'],
    PASSWORD_EMPTY: intl.messages['error.passwordEmpty'],
    PASSWORD_SHORT: intl.messages['error.passwordShort'],
    PASSWORD_FORMAT: intl.messages['error.passwordInvalid'],
    PASSWORD_MISMATCH: intl.messages['error.passwordMismatch'],
    REQUIRED_FIELD: intl.messages['error.fieldRequired'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={profileEditSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, errors, touched, dirty, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
        <form className="profile-edit-form" onSubmit={handleSubmit}>
        <InputTextField
          className="profile-edit-form__input"
          placeholder={intl.messages['register.name']}
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name && errors.name}
        />
        <InputTextField
          className="profile-edit-form__input"
          placeholder={intl.messages['register.phone']}
          name="phone"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.phone && errors.phone && errors.phone}
        />
        <p className="profile-edit-form__message">
          {intl.messages['profile.content.passwordOptional']}
        </p>
        <InputTextField
          className="profile-edit-form__input"
          placeholder={intl.messages['register.password']}
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          error={touched.password && errors.password && errors.password}
        />
        <InputTextField
          className="profile-edit-form__input"
          placeholder={intl.messages['resetPassword.passwordConfirmation']}
          name="passwordConfirmation"
          value={values.passwordConfirmation}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          error={touched.passwordConfirmation && errors.passwordConfirmation && errors.passwordConfirmation}
        />
        <Button
          className='profile-edit-form__button'
          label={intl.messages['common.edit']}
          type='submit'
          onSubmit={handleSubmit}
          disabled={isSubmitting || Object.keys(errors).length || !dirty}
        />
        </form>
      )}
    </Formik>
  );
}

export default ProfileEditForm;
