import React from 'react';
import { useNavigate } from 'react-router';
import * as classnames from 'classnames';
import { AppContext } from '../../../../../storage/context';
import { PATHS } from '../../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const LogoutButton = props => {
  const dispatch = React.useContext(AppContext)[1];

  const { className } = props;

  const navigate = useNavigate();

  const nullToken = () => {
    dispatch({
      type: 'nullToken',
    });
  };

  const nullUser = () => {
    dispatch({
      type: 'nullUser',
    });
  };

  const logOut = () => {
    nullToken();
    sessionStorage.clear();
    localStorage.clear();
    nullUser();
    navigate(PATHS.root);
  };

  return (
    <div
      className={classnames(
        'logout-button',
        className && className,
      )}
      onClick={logOut}
    >
      <FontAwesomeIcon icon={faSignOut} className="logout-button__icon" />
    </div>
  );
};

export default LogoutButton;
