import React from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from "react-transition-group";
import { MATCH, TOP } from '../../../utils/constants';
import { AppContext } from '../../../storage/context';
import Button45 from '../ui/Buttons/Button45';
import './index.scss';

const Modal = () => {
  const [{ modalData = {} }, dispatch] = React.useContext(AppContext);
  const [currentModalData, setCurrentModalData] = React.useState({});

  const nodeRef = React.useRef(null);

  const intl = useIntl();
  const showModal = modalData.title || modalData.description || modalData.form;

  const closeModal = () => {
    dispatch({
      type: 'nullModalData',
    });
  };

  React.useEffect(() => {
    const modalNotEmpty = modalData.title || modalData.description || modalData.form;

    if (modalNotEmpty) {
      setCurrentModalData(modalData);
    }
  }, [modalData]);

  return (
    <CSSTransition nodeRef={nodeRef} in={Boolean(showModal)} timeout={300} classNames="react-transition">
      <div className="modal" ref={nodeRef}>
        <div className="modal__container">
          <div className="modal__backdrop"></div>
          <div className="modal__content">
            <div className="modal__body">
              <div className="modal__title">
                <span className="modal__title-part -secondary">{TOP}</span>
                <span className="modal__title-part -primary">{MATCH}</span>
              </div>
              {currentModalData.title && (
                <h3
                  className="modal__body-title"
                  dangerouslySetInnerHTML={{ __html: currentModalData.title }}
                ></h3>
              )}
              {currentModalData.description && (
                <p
                  className="modal__body-text"
                  dangerouslySetInnerHTML={{ __html: currentModalData.description || "" }}
                ></p>
              )}
            </div>
            {currentModalData?.form && (
              <div className="modal__form-container">
                {currentModalData.form}
              </div>
            )}
            {!currentModalData?.form && (
              <div className="modal__footer">
                <Button45
                  className="modal__button -primary"
                  label={intl.messages['common.ok']}
                  boldLabel
                  handleClick={closeModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
