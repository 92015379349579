import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as classnames from 'classnames';
import { myCandidateNameSchema } from '../../../utils/validationSchemas';
import InputTextField from '../../common/ui/InputTextField';
import Button from '../../common/ui/Buttons/Button';

const MyCandidateNameForm = (props) => {
  const { initialValues, onSubmit } = props;

  const intl = useIntl();

  const ERROR_MESSAGES = {
    REQUIRED_FIELD: intl.messages['error.fieldRequired'],
    TOO_LONG: intl.messages['error.tooLong'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={myCandidateNameSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className="my-candidate-page-name__form" onSubmit={handleSubmit}>
          <InputTextField
            className="my-candidate-page-name__form-input"
            placeholder={intl.messages['myCandidate.name.name']}
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />

          <Button
            className={classnames(
              'my-candidate-page-name__button',
              '-secondary',
            )}
            label={intl.messages['myCandidate.name.createCandidate']}
            type='submit'
            onSubmit={handleSubmit}
            disabled={isSubmitting || Object.keys(errors).length}
          />
        </form>
      )}
    </Formik>
  );
}

export default MyCandidateNameForm;
