import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../storage/context';
import { API_BASE_URL, PATHS, LANGUAGE, LANGUAGES, CASE_SUBPAGES } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../../common/ScreenResolver';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import MenuButton from '../../common/ui/Buttons/MenuButton';
import BackButton from '../../common/ui/Buttons/BackButton';
import palceholderBackground from '../../../assets/images/backgrounds/placeholder.png';
import './index.scss';

const CaseMenuPage = () => {
  const { helpers = {} } = React.useContext(AppContext)[0];

  const intl = useIntl();
  
  const { caseCategories, cases } = helpers;
  const { caseCategorySlug } = useParams();
  const caseCategory = React.useMemo(() => {
    return caseCategories?.find(category => category.slug === caseCategorySlug);
  }, [caseCategories, caseCategorySlug]);

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  return (
    <div className="case-menu-page">
      <div className="case-menu-page__container">
        <TopMenuLayerMobile />
        <div className="case-menu-page__mobile-title">
          {intl.messages['common.cases']}
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <div className="case-menu-page__content">
              <div className="case-menu-page__text">
                <h1
                  className="case-menu-page__title"
                  dangerouslySetInnerHTML={{ __html: caseCategory && caseCategory[selectedLanguageCode].header }}
                >
                </h1>
                <div className="case-menu-page__descriptions">
                  <p
                    className="case-menu-page__description"
                    dangerouslySetInnerHTML={{ __html: caseCategory && caseCategory[selectedLanguageCode].description }}
                  >
                  </p>
                </div>
              </div>
              <div className="case-menu-page__menu">
                {caseCategory?.cases && caseCategory.cases
                  .sort((case1, case2) => case1.order - case2.order)
                  .map(caseId => {
                    const caseItem = cases.find(caseItem => caseItem.id === caseId);
                    return (
                      <MenuButton
                        key={caseItem?.id}
                        className="case-menu-page__menu-button"
                        label={caseItem?.[selectedLanguageCode].name}
                        navigateTo={`${PATHS.cases}/${caseCategory.slug}/${caseItem.slug}/${CASE_SUBPAGES.CASE.slug}`}
                      />
                    );
                  })}
              </div>
            </div>
          }
          mobile={
            <div className="case-menu-page__content">
              <div className="case-menu-page__menu">
                <div className="case-menu-page__menu-button-container">
                  {caseCategory?.cases && caseCategory.cases
                    .sort((case1, case2) => case1.order - case2.order)
                    .map(caseId => {
                      const caseItem = cases.find(caseItem => caseItem.id === caseId);
                      return (
                        <MenuButton
                          key={caseItem?.id}
                          className="case-menu-page__menu-button"
                          label={caseItem?.[selectedLanguageCode].name}
                          navigateTo={`${PATHS.cases}/${caseCategory.slug}/${caseItem.slug}/${CASE_SUBPAGES.CASE.slug}`}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          }
        />
        <div className="case-menu-page__bottom-section">
          <BackButton className="case-menu-page__back-button" />
        </div>
        <img className="case-menu-page__background" src={`${API_BASE_URL}${caseCategory?.image}` || palceholderBackground} alt="background" />
      </div>
    </div>
  );
};

export default CaseMenuPage;
