import React from "react";
import { getFaqs } from "../../api/services/Faq";
import { AppContext } from "../../storage/context";
import useLoadingLayer from "./useLoadingLayer";
import useSetError from "./useSetError";

const useFaq = () => {
  const [{ faqList=[] }, dispatch] = React.useContext(AppContext);

  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  React.useEffect(() => {
    const fetchFaqs = async () => {
      try {
        loadingLayer.show();
        const faqs = await getFaqs(setError);
        if (faqs.length) {
          dispatch({
            type: 'setFaqList',
            faqList: faqs,
          });
        }
      } catch(err) {
        console.error(err);
      } finally {
        loadingLayer.hide();
      }
    };

    fetchFaqs();
  }, [dispatch, loadingLayer, setError]);

  return React.useMemo(() => (
    { list: faqList }
  ), [faqList]);
};

export default useFaq;
