import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { registerSchema } from '../../../utils/validationSchemas';
import InputTextField from '../../common/ui/InputTextField';
import Checkbox from '../../common/ui/Checkbox';
import Button from '../../common/ui/Buttons/Button';
import { PATHS } from '../../../utils/constants';

const RegisterForm = (props) => {
  const { onSubmit } = props;

  const navigate = useNavigate();
  const intl = useIntl();

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    password: '',
    acceptTerms: false,
  };

  const ERROR_MESSAGES = {
    EMAIL_EMPTY: intl.messages['error.emailEmpty'],
    EMAIL_FORMAT: intl.messages['error.emailInvalid'],
    PASSWORD_EMPTY: intl.messages['error.passwordEmpty'],
    PASSWORD_FORMAT: intl.messages['error.passwordInvalid'],
    PASSWORD_SHORT: intl.messages['error.passwordShort'],
    REQUIRED_FIELD: intl.messages['error.fieldRequired'],
    ACCEPT_TERMS_REQUIRED: intl.messages['error.acceptTerms'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
        <form className="register-page__form" onSubmit={handleSubmit}>
          <InputTextField
            className="register-page__form-input"
            placeholder={intl.messages['register.name']}
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name && errors.name}
          />
          <InputTextField
            className="register-page__form-input"
            placeholder={intl.messages['register.email']}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email && errors.email}
          />
          <InputTextField
            className="register-page__form-input"
            placeholder={intl.messages['register.phone']}
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && errors.phone && errors.phone}
          />
          <InputTextField
            className="register-page__form-input"
            placeholder={intl.messages['register.password']}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            error={touched.password && errors.password && errors.password}
          />
          <div className="register-page__form-checkbox-container">
            <Checkbox
              className="register-page__form-checkbox"
              name="acceptTerms"
              text=""
              onChange={handleChange}
              value={values.acceptTerms}
              error={
                touched.acceptTerms &&
                errors.acceptTerms &&
                errors.acceptTerms
              }
            />
            <p className="register-page__form-checkbox-text">
              {intl.messages['register.accept']}
              <span
                className="register-page__form-checkbox-link"
                onClick={() => navigate(PATHS.privacyPolicy)}
              >
                &nbsp;{intl.messages['register.terms']}
              </span>
            </p>
          </div>
          <div className="register-page__buttons">
            <Button
              className='register-page__button -ok'
              label={intl.messages['common.ok']}
              type='submit'
              onSubmit={handleSubmit}
              disabled={isSubmitting || Object.keys(errors).length}
            />
            <Button
              className='register-page__button -login -secondary'
              label={intl.messages['common.toLogin']}
              handleClick={() => navigate(PATHS.login)}
            />
          </div>
          <p
            className="register-page__resend-verification"
            onClick={() => navigate(PATHS.resendVerifyMail)}
          >{intl.messages['login.resendVerificationMail']}</p>
        </form>
      )}
    </Formik>
  );
}

export default RegisterForm;
