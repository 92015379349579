import React from 'react';
import useUser from '../../../../../utils/hooks/useUser';
import ProfileEditForm from '../../../../forms/ProfileEditForm';
import './index.scss';

const ProfilePageEdit = props => {
  const { redirectToMenuDefault } = props;

  const user = useUser(redirectToMenuDefault);

  const initialValues = {
    name: user.user.name || '',
    phone: user.user.phone || '',
    password: '',
    passwordConfirmation: '',
  };

  return (
    <div className="profile-page-edit">
      <ProfileEditForm
        initialValues={initialValues}
        onSubmit={user?.edit}
      />
    </div>
  );
};

export default ProfilePageEdit;
