import React from 'react';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { createDoubleDigitPositiveIntegerString } from '../../../utils/helpers';
import CloseButton from '../ui/Buttons/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const MyCandidateListItem = props => {
  const { myCandidate, deleteMyCandidate } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const intl = useIntl();

  const date = new Date(myCandidate?.createdAt);
  const formattedDate = `${createDoubleDigitPositiveIntegerString(date.getDate())}.${createDoubleDigitPositiveIntegerString(date.getMonth() + 1)}.${date.getFullYear()}`;

  return (
    <div
      className="my-candidate-list-item"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="my-candidate-list-item__header">
        <div className="my-candidate-list-item__arrow-container">
          <FontAwesomeIcon
            icon={faPlay}
            className={classnames(
              'my-candidate-list-item__arrow',
              isOpen && '-downwards',
            )}
          />
        </div>
        <p className="my-candidate-list-item__name">{myCandidate?.name}</p>
        <p className="my-candidate-list-item__created-at">{formattedDate}</p>
        <div className="my-candidate-list-item__delete-button-container">
          <CloseButton
            handleClick={(event) => {
              event.stopPropagation();
              deleteMyCandidate(myCandidate.id)}
            }
          />
        </div>
      </div>
      <div className={classnames(
        'my-candidate-list-item__body',
        isOpen && '-open',
      )}>
        {(myCandidate.educationText || myCandidate.educationImportance) && (
          <div className="my-candidate-list-item__section">
            <h3 className="my-candidate-list-item__section-title">
              {intl.messages['myCandidate.education.title']}
            </h3>
            {myCandidate.educationText && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.educationText}
              </p>
            )}
            {myCandidate.educationImportance && (
              <h3 className="my-candidate-list-item__section-importance">
                {myCandidate.educationImportance}&nbsp;{intl.messages['profile.content.outOf']}&nbsp;10
              </h3>
            )}
          </div>
        )}
        {(myCandidate.experienceText || myCandidate.experienceImportance) && (
          <div className="my-candidate-list-item__section">
            <h3 className="my-candidate-list-item__section-title">
              {intl.messages['myCandidate.experience.title']}
            </h3>
            {myCandidate.experienceText && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.experienceText}
              </p>
            )}
            {myCandidate.experienceImportance && (
              <h3 className="my-candidate-list-item__section-importance">
                {myCandidate.experienceImportance}&nbsp;{intl.messages['profile.content.outOf']}&nbsp;10
              </h3>
            )}
          </div>
        )}
        {(myCandidate.specialsText || myCandidate.specialsImportance) && (
          <div className="my-candidate-list-item__section">
            <h3 className="my-candidate-list-item__section-title">
              {intl.messages['myCandidate.specials.title']}
            </h3>
            {myCandidate.specialsText && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.specialsText}
              </p>
            )}
            {myCandidate.specialsImportance && (
              <h3 className="my-candidate-list-item__section-importance">
                {myCandidate.specialsImportance}&nbsp;{intl.messages['profile.content.outOf']}&nbsp;10
              </h3>
            )}
          </div>
        )}
        {(myCandidate.certificationsText || myCandidate.certificationsImportance) && (
          <div className="my-candidate-list-item__section">
            <h3 className="my-candidate-list-item__section-title">
              {intl.messages['myCandidate.certifications.title']}
            </h3>
            {myCandidate.certificationsText && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.certificationsText}
              </p>
            )}
            {myCandidate.certificationsImportance && (
              <h3 className="my-candidate-list-item__section-importance">
                {myCandidate.certificationsImportance}&nbsp;{intl.messages['profile.content.outOf']}&nbsp;10
              </h3>
            )}
          </div>
        )}
        {(myCandidate.languagesText || myCandidate.languagesImportance) && (
          <div className="my-candidate-list-item__section">
            <h3 className="my-candidate-list-item__section-title">
              {intl.messages['myCandidate.languages.title']}
            </h3>
            {myCandidate.languagesText && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.languagesText}
              </p>
            )}
            {myCandidate.languagesImportance && (
              <h3 className="my-candidate-list-item__section-importance">
                {myCandidate.languagesImportance}&nbsp;{intl.messages['profile.content.outOf']}&nbsp;10
              </h3>
            )}
          </div>
        )}
        {(myCandidate.projectStart || myCandidate.projectDuration || myCandidate.projectDescription) && (
          <div className="my-candidate-list-item__section">
            <h3 className="my-candidate-list-item__section-title">
              {intl.messages['myCandidate.project.title']}
            </h3>
            {myCandidate.projectStart && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.projectStart}
              </p>
            )}
            {myCandidate.projectDuration && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.projectDuration}
              </p>
            )}
            {myCandidate.projectDescription && (
              <p className="my-candidate-list-item__section-text">
                {myCandidate.projectDescription}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyCandidateListItem;
