import { ERROR_OCCURRED, LANGUAGE, LANGUAGES, TOKEN } from './constants';

export const handleErrorSet = (error = {}, setError = () => { }, translatedMessage = null) => {
  const localLanguage = localStorage.getItem(LANGUAGE);
  const currentLanguage = localLanguage || LANGUAGES.DK;

  const text = translatedMessage || ERROR_OCCURRED[currentLanguage];
  const code = error?.response?.status || 400;
  if(code === 401) {
    sessionStorage.removeItem(TOKEN);
  }
  return setError({ text, code });
};
