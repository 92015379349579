import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../storage/context';
import { MY_CANDIDATE_STEPS, PATHS } from '../../../../utils/constants';
import MyCandidateLanguagesForm from '../../../forms/MyCandidateLanguagesForm';
import './index.scss'

const MyCandidatePageLanguages = props => {
  const { myCandidate } = React.useContext(AppContext)[0];
  const { updateMyCandidate, stepForward, stepBack } = props;

  const navigate = useNavigate();
  const intl = useIntl();

  const myCandidateId = myCandidate?.id;

  const initialValues = {
    languagesText: myCandidate?.languagesText || '',
    languagesImportance: myCandidate?.languagesImportance || 0,
  };

  React.useEffect(() => {
    if (!myCandidateId) {
      navigate(`${PATHS.myCandidate}/${MY_CANDIDATE_STEPS.find(step => step.order === 2)?.slug}`);
    }
  }, [myCandidateId, navigate]);

  return (
    <div className="my-candidate-page-languages">
      <div className="my-candidate-page-languages__data-container-mobile">
        <h3 className="my-candidate-page-languages__data-mobile">{myCandidate?.userEmail || ''}</h3>
        <h3 className="my-candidate-page-languages__data-mobile">{myCandidate?.name || ''}</h3>
      </div>
      <h2 className="my-candidate-page-languages__title">
        {intl.messages['myCandidate.languages.title']}
        <div className="my-candidate-page-languages__icon" />
      </h2>
      <p
        className="my-candidate-page-languages__description"
        dangerouslySetInnerHTML={{ __html: intl.messages['myCandidate.languages.description'] }}
      />
      <MyCandidateLanguagesForm
        initialValues={initialValues}
        onSubmit={updateMyCandidate}
        stepForward={stepForward}
        stepBack={stepBack}
      />
    </div>
  );
};

export default MyCandidatePageLanguages;
