import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../storage/context';
import CandidateDisplay from '../../common/CandidateDisplay';
import { PATHS } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../../common/ScreenResolver';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import CandidateCardMobile from '../../common/CandidateCardMobile';
import Button from '../../common/ui/Buttons/Button';
import BackButton from '../../common/ui/Buttons/BackButton';
import './index.scss';
import Button34 from '../../common/ui/Buttons/Button34';

const CandidatesSearchResultPage = () => {
  const [{ candidateSearchList = [] }, dispatch] = React.useContext(AppContext);

  const intl = useIntl();
  const navigate = useNavigate();

  const initialValues = {
    mainCategory: '',
    subcategory: '',
    tools: [null],
    skills: [],
    allSkills: [],
  };

  const navigateToCandidate = candidateId => {
    const path = `${PATHS.candidateBySearch}/${candidateId}`;

    navigate(`${path}`);
  };

  const resetSearch = () => {
    dispatch({
      type: 'setCandidateSearchList',
      candidateSearchList: [],
    });
    dispatch({
      type: 'nullCandidateSearchParams',
    });
    dispatch({
      type: 'setCandidateSearchParamsDisplayed',
      candidateSearchParamsDisplayed: initialValues,
    });
    navigate(PATHS.candidateSearch);
  };

  return (
    <div className="candidates-search-result-page">
      <div className="candidates-search-result-page__container">
        <TopMenuLayerMobile />
        <div className="candidates-search-result-page__mobile-title">
          {`${candidateSearchList?.length || 0} `}
          {candidateSearchList?.length === 1 ?
            intl.messages['candidateList.profile'] :
            intl.messages['candidateList.profiles']
          }
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <>
              <div className="candidates-search-result-page__header">
                <h1 className="candidates-search-result-page__title">
                  <span
                    className="candidates-search-result-page__title-part-1"
                    dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.titlePart1'] }}
                  ></span>
                  <span className="candidates-search-result-page__title-space">{'\u00A0'}</span>
                  <span
                    className="candidates-search-result-page__title-part-2"
                    dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.titlePart2'] }}
                  ></span>
                </h1>
              </div>
              <div className="candidates-search-result-page__content">
                <CandidateDisplay
                  candidates={candidateSearchList}
                  navigateToCandidate={(candidateId) => navigate(`${PATHS.candidateBySearch}/${candidateId}`)}
                />
              </div>
              <div className="candidates-search-result-page__footer">
                <div className="candidates-search-result-page__button-container">
                  <Button
                    className="candidates-search-result-page__button-modify"
                    label={intl.messages['candidateSearch.modifySearch']}
                    handleClick={() => navigate(PATHS.candidateSearch)}
                  />
                  <h4
                    className="candidates-search-result-page__reset"
                    dangerouslySetInnerHTML={{ __html: intl.messages['candidateSearch.reset'] }}
                    onClick={resetSearch}
                  ></h4>
                </div>
              </div>
            </>
          }
          mobile={
            <div className="candidates-search-result-page__content">
              <div className="candidates-search-result-page__card-mobile-container">
                {candidateSearchList.map(candidate => (
                  <CandidateCardMobile
                    key={candidate.id}
                    className="candidate-search-result-page__card-mobile"
                    candidate={candidate}
                    navigateToCandidate={navigateToCandidate}
                  />
                ))}
              </div>
            </div>
          }
        />
        <div className="candidates-search-result-page__bottom-section">
          <div className="candidates-search-result-page__button-container">
            <ScreenResolver
              large={BREAKPOINTS.md}
              desktop={null}
              mobile={
                <Button34
                  className="candidates-search-result-page__button-modify"
                  label={intl.messages['candidateSearch.modifySearch']}
                  handleClick={() => navigate(PATHS.candidateSearch)}
                />
              }
            />
          </div>
          <BackButton className="candidates-search-result-page__back-button" />
        </div>
      </div>
    </div>
  );
};

export default CandidatesSearchResultPage;
