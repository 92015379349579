import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../storage/context';
import { MY_CANDIDATE_STEPS, PATHS, USER, USER_ROLES } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { postDreamCandidate, postUserCheckEmail, putDreamCandidate } from '../../../api/services/User';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import BackButton from '../../common/ui/Buttons/BackButton';
import MyCandidatePageStart from './MyCandidatePageStart';
import MyCandidatePageName from './MyCandidatePageName';
import MyCandidatePageEducation from './MyCandidatePageEducation';
import MyCandidatePageExperience from './MyCandidatePageExperience';
import MyCandidatePageSpecials from './MyCandidatePageSpecials';
import MyCandidatePageCertifications from './MyCandidatePageCertifications';
import MyCandidatePageLanguages from './MyCandidatePageLanguages';
import MyCandidatePageProject from './MyCandidatePageProject';
import MyCandidatePageSuccess from './MyCandidatePageSuccess';
import './index.scss';

const MyCandidatePage = () => {
  const [{ myCandidate = {} }, dispatch] = React.useContext(AppContext);

  const navigate = useNavigate();
  const { myCandidateStep } = useParams();
  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const user = JSON.parse(sessionStorage.getItem(USER));

  const stepForward = () => {
    const currentStep = MY_CANDIDATE_STEPS.find(step => step.slug === myCandidateStep)?.order;
    const nextStepSlug = MY_CANDIDATE_STEPS.find(step => step.order === currentStep + 1)?.slug;
    if (nextStepSlug) {
      navigate(`${PATHS.myCandidate}/${nextStepSlug}`);
    }
  };

  const stepBack = () => {
    const currentStep = MY_CANDIDATE_STEPS.find(step => step.slug === myCandidateStep)?.order;
    const previousStepSlug = MY_CANDIDATE_STEPS.find(step => step.order === currentStep - 1)?.slug;
    if (previousStepSlug) {
      navigate(`${PATHS.myCandidate}/${previousStepSlug}`);
    }
  };

  const startCandidateCreation = async (values, { setSubmitting }) => {
    try {
      loadingLayer.show();
      const response = await postUserCheckEmail(values, setError);
      if (response?.email && response?.id) {
        dispatch({
          type: 'setMyCandidate',
          myCandidate: {
            userId: response.id,
            userEmail: values.userEmail,
          },
        });
        stepForward();
      }
      if (response?.message) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages[response.message],
          },
        });
      }
    } catch(err) {
      console.error(err);
    } finally {
      loadingLayer.hide();
    }

    setSubmitting(false);
  };

  const addName = async (values, { setSubmitting }) => {
    const payload = {
      userId: myCandidate?.userId,
      name: values.name,
    };

    try {
      loadingLayer.show();
      let candidate;
      if (!myCandidate?.id) {
        candidate = await postDreamCandidate(payload, setError);
      } else {
        candidate = await putDreamCandidate(myCandidate?.id, payload, setError);
      }
      if (candidate?.id) {
        dispatch({
          type: 'setMyCandidate',
          myCandidate: candidate,
        });
        stepForward();
      }
    } catch(error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }
    setSubmitting(false);
  };

  const updateMyCandidate = async (values, { setSubmitting }) => {
    const payload = values;
    
    try {
      loadingLayer.show();
      const candidate = await putDreamCandidate(myCandidate?.id, payload, setError);
      if (candidate.id) {
        dispatch({
          type: 'setMyCandidate',
          myCandidate: candidate,
        });
        stepForward();
      }
    } catch(error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }

    setSubmitting(false);
  };

  const renderSubpage = () => {
    switch (myCandidateStep) {
      case MY_CANDIDATE_STEPS.find(step => step.order === 1)?.slug:
        return (
          <MyCandidatePageStart
            startCandidateCreation={startCandidateCreation}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 2)?.slug:
        return (
          <MyCandidatePageName
            addName={addName}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 3)?.slug:
        return (
          <MyCandidatePageEducation
            updateMyCandidate={updateMyCandidate}
            stepForward={stepForward}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 4)?.slug:
        return (
          <MyCandidatePageExperience
            updateMyCandidate={updateMyCandidate}
            stepForward={stepForward}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 5)?.slug:
        return (
          <MyCandidatePageSpecials
            updateMyCandidate={updateMyCandidate}
            stepForward={stepForward}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 6)?.slug:
        return (
          <MyCandidatePageCertifications
            updateMyCandidate={updateMyCandidate}
            stepForward={stepForward}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 7)?.slug:
        return (
          <MyCandidatePageLanguages
            updateMyCandidate={updateMyCandidate}
            stepForward={stepForward}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 8)?.slug:
        return (
          <MyCandidatePageProject
            updateMyCandidate={updateMyCandidate}
            stepForward={stepForward}
            stepBack={stepBack}
          />
        );
      case MY_CANDIDATE_STEPS.find(step => step.order === 9)?.slug:
        return (
          <MyCandidatePageSuccess />
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (user?.role?.toUpperCase() !== USER_ROLES.ADMIN.toUpperCase()) {
      navigate(PATHS.menu);
    }
  }, [user, navigate]);

  React.useEffect(() => {
    return () => {
      dispatch({
        type: 'setMyCandidate',
        myCandidate: null,
      });
    };
  }, [dispatch]);

  return (
    <div className="my-candidate-page">
      <div className="my-candidate-page__container">
        <TopMenuLayerMobile />
        <div className="my-candidate-page__title">
          {`${intl.messages['myCandidate.start.title']} ${intl.messages['myCandidate.start.candidate']}`}
        </div>
        {renderSubpage()}
        <div className="my-candidate-page__bottom-section">
          <BackButton
            className="my-candidate-page__back-button"
            disabled={myCandidateStep === MY_CANDIDATE_STEPS.find(step => step.order === 2)?.slug}
          />
        </div>
      </div>
      <div className="my-candidate-page__data-container">
        <h3 className="my-candidate-page__data">{myCandidate?.userEmail || ''}</h3>
        <h3 className="my-candidate-page__data">{myCandidate?.name || ''}</h3>
      </div>
    </div>
  );
};

export default MyCandidatePage;
