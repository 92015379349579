import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const LevelIndicator = props => {
  const {
    className,
    level,
    sizePx=10,
  } = props;

  return (
    <div
      className={classnames(
        'level-indicator',
        className && className,
      )}
    >
      {[1, 2, 3, 4, 5].map(position => (
        <div
          key={position}
          className={classnames(
            'level-indicator__dot',
            `-size-${sizePx}`,
            position > level && '-empty',
          )}
        ></div>
      ))}
    </div>
  );
};

export default LevelIndicator;
