import React from 'react';
import './index.scss';

const SlickDots = props => {
  const { dots } = props;

  return (
    <div className="slick-dots">
      <ul className="slick-dots__list">{dots}</ul>
    </div>
  );
};

export default SlickDots;
