export const API_BASE_URL = process.env.REACT_APP_URL;
export const API_URL = `${API_BASE_URL}/api`;

export const TOKEN = 'token';
export const USER = 'user';
export const USERNAME = 'username';
export const LANGUAGE = 'language';
export const STORAGE = 'storage';
export const MENU = 'menu';
export const TOPMATCH = 'topmatch';
export const INTL = 'intl';
export const USER_ROLE = 'User';

export const TWO_WEEKS_MS = 12096e5;
export const EXP_DATE = 'token-expiration-date'

//eslint-disable-next-line no-useless-escape
export const TRANSLATE_REGEXP = /[^\x20-\x7E]|[\s-\/()-]/g;

//TRANSLATION CONSTANTS
export const TOP = 'Top';
export const MATCH = 'Match';
export const ERROR_OCCURRED = {
  dk: 'Der opstod en fejl<br />Prøv venligst igen eller genindlæs siden',
  en: 'An error occurred<br />Please retry or reload the page',
};

export const PATHS = {
  root: '/',
  notFound: '/not-found',
  register: '/register',
  registerSuccess: '/register-success',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  resendVerifyMail: '/resend-verification-mail',
  login: '/login',
  privacyPolicy: '/privacy-policy',
  menu: '/menu',
  categories: '/categories',
  newCandidate: '/new-candidate',
  candidateSearch: '/candidate-search',
  searchResult: '/search-result',
  candidateByCategory: '/candidate-by-category',
  candidateBySearch: '/candidate-by-search',
  candidateByCase: '/candidate-by-case',
  profile: '/profile',
  profileSubroutes: {
    edit: '/edit',
    savedCandidates: '/saved-candidates',
    myCandidates: '/my-candidates',
    faq: '/faq',
  },
  cases: '/cases',
  candidatesByCase: '/candidates-by-case',
  myCandidate: '/my-candidate',
  params: {
    mainCategorySlug: '/:mainCategorySlug',
    subcategorySlug: '/:subcategorySlug',
    candidateId: '/:candidateId',
    myCandidateStep: '/:myCandidateStep',
    caseCategorySlug: '/:caseCategorySlug',
    caseSlug: '/:caseSlug',
    caseSubpageSlug: '/:caseSubpageSlug',
  },
};

export const UNPROTECTED_PATHS = [
  PATHS.root,
  PATHS.notFound,
  PATHS.register,
  PATHS.registerSuccess,
  PATHS.forgotPassword,
  PATHS.resetPassword,
  PATHS.resendVerifyMail,
  PATHS.login,
  PATHS.privacyPolicy,
];

export const MY_CANDIDATE_STEPS = [
  {
    order: 1,
    slug: 'start',
  },
  {
    order: 2,
    slug: 'name',
  },
  {
    order: 3,
    slug: 'education',
  },
  {
    order: 4,
    slug: 'experience',
  },
  {
    order: 5,
    slug: 'specials',
  },
  {
    order: 6,
    slug: 'certifications',
  },
  {
    order: 7,
    slug: 'languages',
  },
  {
    order: 8,
    slug: 'project-informations',
  },
  {
    order: 9,
    slug: 'success',
  },
];

export const USER_ROLES = {
  ADMIN: 'Admin',
  USER: 'User',
};

export const LANGUAGES = {
  DK: 'dk',
  EN: 'en',
};

export const PROFILE_SUBPAGES = {
  SAVED_CANDIDATES: 'Saved Candidates',
  MY_CANDIDATES: 'My Candidates',
  FAQ: 'Faq',
  EDIT: 'Edit',
};

export const CASE_SUBPAGES = {
  CASE: {
    name: 'Case',
    slug: 'case',
    translationKey: 'case.case',
  },
  CHALLENGE: {
    name: 'Challenge',
    slug: 'challenge',
    translationKey: 'case.challenge',
  },
  SOLUTION: {
    name: 'Solution',
    slug: 'solution',
    translationKey: 'case.solution',
  },
  RESULT: {
    name: 'Result',
    slug: 'result',
    translationKey: 'case.result',
  },
};

export const PROFILE_MENU = [
  {
    order: 1,
    labelKey: 'profile.menu.savedCandidates',
    content: PROFILE_SUBPAGES.SAVED_CANDIDATES,
    subRoute: PATHS.profileSubroutes.savedCandidates,
  },
  {
    order: 2,
    labelKey: 'profile.menu.myCandidate',
    content: PROFILE_SUBPAGES.MY_CANDIDATES,
    subRoute: PATHS.profileSubroutes.myCandidates,
  },
  {
    order: 3,
    labelKey: 'profile.menu.faq',
    content: PROFILE_SUBPAGES.FAQ,
    subRoute: PATHS.profileSubroutes.faq,
  },
  {
    order: 4,
    labelKey: 'profile.menu.editMyData',
    content: PROFILE_SUBPAGES.EDIT,
    subRoute: PATHS.profileSubroutes.edit,
  },
];

export const CONTACT_METHODS = {
  EMAIL: 'email',
  PHONE: 'phone',
};

export const TRACKING_EVENT_NAMES = {
  MAIN_MENU_CANDIDATES_CLICK: 'main-menu-candidates-click',
  MAIN_MENU_COMPETENCE_CLICK: 'main-menu-competence-click',
  MAIN_MENU_CASES_CLICK : 'main-menu-cases-click',
  MAIN_CATEGORY_CLICK: 'main-category-click',
  SUBCATEGORY_CLICK: 'subcategory-click',
  CANDIDATE_SEARCH: 'candidate-search',
  CANDIDATE_DISPLAY: 'candidate-display',
};
