import React from 'react';
import { useIntl } from 'react-intl';
import useFaq from '../../../../../utils/hooks/useFaq';
import FaqItem from '../../../../common/FaqItem';
import './index.scss';

const ProfilePageFaq = () => {
  const intl = useIntl();
  const faq = useFaq();

  return (
    <div className="profile-page-faq">
      {faq.list?.length ? (
        faq.list?.map((faq, i) => (
          <FaqItem
            key={i}
            faq={faq}
          />
        ))) : (
        <p
          className="profile-page-faq__fallback-message"
          dangerouslySetInnerHTML={{ __html: intl.messages['profile.content.noFaq'] }}
        ></p>
      )}
    </div>
  );
};

export default ProfilePageFaq;
