import React from 'react';

const useSwipe = (handlers={}) => {
  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);

  const {
    handleSwipeLeft,
    handleSwipeRight,
  } = handlers;

  const minSwipeDistance = 50;

  const onTouchStart = React.useCallback(event => {
    setTouchEnd(null);
    setTouchStart(event.targetTouches[0].clientX);
  }, []);
  
  const onTouchMove = React.useCallback(event => {
    setTouchEnd(event.targetTouches[0].clientX)
  }, []);
  
  const onTouchEnd = React.useCallback(() => {
    if (!touchStart || !touchEnd || touchStart === touchEnd) {
      return;
    }

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleSwipeLeft();
    }
    if (isRightSwipe) {
      handleSwipeRight();
    }
  }, [handleSwipeLeft, handleSwipeRight, touchStart, touchEnd]);

  return React.useMemo(() => (
    { onTouchStart, onTouchMove, onTouchEnd }
  ), [onTouchStart, onTouchMove, onTouchEnd]);
};

export default useSwipe;
