import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../storage/context';
import { LANGUAGE, LANGUAGES, PATHS } from '../../../utils/constants';
import { createQueryFilterList } from '../../../utils/helpers';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { getCandidates } from '../../../api/services/Candidates';
import ScreenResolver from '../../common/ScreenResolver';
import Button34 from '../../common/ui/Buttons/Button34';
import BackButton from '../../common/ui/Buttons/BackButton';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import CandidateCardMobile from '../../common/CandidateCardMobile';
import CandidateDisplay from '../../common/CandidateDisplay';
import './index.scss';

const CandidatesByCasePage = () => {
  const [ { helpers = {}, candidateCaseList = [] }, dispatch ] = React.useContext(AppContext);

  const navigate = useNavigate();
  const intl = useIntl();
  const { caseSlug } = useParams();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { cases } = helpers;
  const currentCase = cases?.find(caseItem => caseItem.slug === caseSlug);
  const caseId = currentCase?.id;

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const navigateToCandidate = candidateId => {
    const path = `${PATHS.candidateByCase}/${candidateId}`;
    const params = new URLSearchParams();
    params.append('caseSlug', caseSlug);

    navigate(`${path}?${params}`);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (caseId) {
          const searchData = {
            case: {
              value: caseId
            },
          };

          loadingLayer.show();
          const candidatesData = await getCandidates(
            createQueryFilterList(searchData),
            setError,
          );
          dispatch({
            type: 'setCandidateCaseList',
            candidateCaseList: candidatesData,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingLayer.hide();
      }
    };

    fetchData();
  }, [dispatch, loadingLayer, setError, caseId]);

  return (
    <div className="candidates-by-case-page">
      <div className="candidates-by-case-page__container">
        <TopMenuLayerMobile />
        <div className="candidates-by-case-page__mobile-title">
          {currentCase?.[selectedLanguageCode].name}
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <>
              <div className="candidates-by-case-page__header">
                <h1 className="candidates-by-case-page__title">
                  {currentCase?.[selectedLanguageCode].name}
                </h1>
              </div>
              <div className="candidates-by-case-page__content">
                <CandidateDisplay
                  candidates={candidateCaseList}
                  navigateToCandidate={navigateToCandidate}
                />
              </div>
              <div className="candidates-by-case-page__footer"></div>
            </>
          }
          mobile={
            <div className="candidates-by-case-page__content">
              <div className="candidates-by-case-page__card-mobile-container">
                {candidateCaseList.map(candidate => (
                  <CandidateCardMobile
                    key={candidate.id}
                    className="candidate-by-case-page__card-mobile"
                    candidate={candidate}
                    navigateToCandidate={navigateToCandidate}
                  />
                ))}
              </div>
            </div>
          }
        />
        <div className="candidates-by-case-page__bottom-section">
          <div className="candidates-by-case-page__button-container">
            <ScreenResolver
              large={BREAKPOINTS.md}
              desktop={null}
              mobile={
                <Button34
                  className="candidates-by-case-page__button-modify"
                  label={intl.messages['candidate.cases']}
                  handleClick={() => navigate(PATHS.cases)}
                />
              }
            />
          </div>
          <BackButton className="candidates-by-case-page__back-button" />
        </div>
      </div>
    </div>
  );
};

export default CandidatesByCasePage;
