import React from 'react';
import { useIntl } from 'react-intl';
import { TOP, MATCH } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { AppContext } from '../../../storage/context';
import LanguageSelectMenu from '../../common/LanguageSelectMenu';
import BackButton from '../../common/ui/Buttons/BackButton';
import ResendVerifyMailForm from '../../forms/ResendVerifyMailForm';
import { postResendVerifyMail } from '../../../api/services/Auth';
import './index.scss';

const ResendVerifyMailPage = () => {
  const dispatch = React.useContext(AppContext)[1];

  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();
  
  const resendVerificationMail = async (values, { setSubmitting }) => {
    const payload = {
      email: values.email,
    };

    try {
      loadingLayer.show();
      const response = await postResendVerifyMail(payload, setError, intl);

      if (response) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages['modal.resendVerifyMail.message'],
          },
        });
      }
    } catch(error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }

    setSubmitting(false);
  };

  return (
    <div className="resend-verify-mail-page">
      <div className="resend-verify-mail-page__container">
        <LanguageSelectMenu
          className="login-page__language-select-menu"
        />
        <div className="resend-verify-mail-page__title">
          <h1 className="resend-verify-mail-page__title-front">{TOP}</h1>
          <h1 className="resend-verify-mail-page__title-back">{MATCH}</h1>
        </div>
        <h3
          className="resend-verify-mail-page__verify"
          dangerouslySetInnerHTML={{ __html: intl.messages['resendVerifyMail.title'] }}
        ></h3>
        <p
          className="resend-verify-mail-page__description"
          dangerouslySetInnerHTML={{ __html: intl.messages['resendVerifyMail.description'] }}
        ></p>
        <ResendVerifyMailForm onSubmit={resendVerificationMail} />
      </div>
      <BackButton />
    </div>
  );
};

export default ResendVerifyMailPage;
