import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../storage/context';
import { MY_CANDIDATE_STEPS, PATHS } from '../../../../utils/constants';
import MyCandidateProjectForm from '../../../forms/MyCandidateProjectForm';
import './index.scss'

const MyCandidatePageProject = props => {
  const { myCandidate } = React.useContext(AppContext)[0];
  const { updateMyCandidate, stepForward, stepBack } = props;

  const navigate = useNavigate();
  const intl = useIntl();

  const myCandidateId = myCandidate?.id;

  const initialValues = {
    projectStart: myCandidate?.projectStart || '',
    projectDuration: myCandidate?.projectDuration || '',
    projectDescription: myCandidate?.projectDescription || '',
  };

  React.useEffect(() => {
    if (!myCandidateId) {
      navigate(`${PATHS.myCandidate}/${MY_CANDIDATE_STEPS.find(step => step.order === 2)?.slug}`);
    }
  }, [myCandidateId, navigate]);

  return (
    <div className="my-candidate-page-project">
      <div className="my-candidate-page-project__data-container-mobile">
        <h3 className="my-candidate-page-project__data-mobile">{myCandidate?.userEmail || ''}</h3>
        <h3 className="my-candidate-page-project__data-mobile">{myCandidate?.name || ''}</h3>
      </div>
      <h2 className="my-candidate-page-project__title -secondary">
        {intl.messages['myCandidate.project.title']}
        <span className="my-candidate-page-project__title">{intl.messages['myCandidate.project.informations']}</span>
        <div className="my-candidate-page-project__icon" />
      </h2>
      <p
        className="my-candidate-page-project__description"
        dangerouslySetInnerHTML={{ __html: intl.messages['myCandidate.project.description'] }}
      />
      <MyCandidateProjectForm
        initialValues={initialValues}
        onSubmit={updateMyCandidate}
        stepForward={stepForward}
        stepBack={stepBack}
      />
    </div>
  );
};

export default MyCandidatePageProject;
