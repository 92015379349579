import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import LanguageSelectMenu from '../../common/LanguageSelectMenu';
import RegisterForm from '../../forms/RegisterForm';
import { postRegister } from '../../../api/services/Auth';
import './index.scss';

const RegisterPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const register = async (values, { setSubmitting }) => {
    const registerData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
    };

    try {
      loadingLayer.show();
      const data = await postRegister(registerData, setError, intl);
      if (data) {
        navigate(PATHS.registerSuccess);
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }
    
    setSubmitting(false);
  };

  return (
    <div className="register-page">
      <div className="register-page__container">
        <LanguageSelectMenu
          className="register-page__language-select-menu"
        />
        <div className="register-page__title">
          <h1
            className="register-page__title-front"
            dangerouslySetInnerHTML={{ __html: intl.messages["register.new"] }}
          ></h1>
          <h1 className="register-page__title-front">{'\u00A0'}</h1>
          <h1
            className="register-page__title-back"
            dangerouslySetInnerHTML={{ __html: intl.messages["register.user"] }}
          ></h1>
        </div>
        <p
          className="register-page__instruction"
          dangerouslySetInnerHTML={{ __html: intl.messages["register.description1"] }}
        ></p>
        <RegisterForm onSubmit={register} />
        <div className="register-page__descriptions">
          <p
            className="register-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages["register.description2"] }}
          ></p>
          <p
            className="register-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages["register.description3"] }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
