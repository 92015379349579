import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { forgotPasswordSchema } from '../../../utils/validationSchemas';
import InputTextField from '../../common/ui/InputTextField';
import Button from '../../common/ui/Buttons/Button';

const PasswordForgotForm = (props) => {
  const { onSubmit } = props;

  const intl = useIntl();

  const initialValues = {
    email: '',
  };

  const ERROR_MESSAGES = {
    EMAIL_EMPTY: intl.messages['error.emailEmpty'],
    EMAIL_FORMAT: intl.messages['error.emailInvalid'],
    REQUIRED_FIELD: intl.messages['error.fieldRequired'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, touched, errors, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
        <form className="password-forgot-page__form" onSubmit={handleSubmit}>
          <InputTextField
            className="password-forgot-page__form-input"
            placeholder={intl.messages['register.email']}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email && errors.email}
          />

          <div className="password-forgot-page__buttons">
            <Button
                className='password-forgot-page__button'
                label={intl.messages['common.send']}
                type='submit'
                onSubmit={handleSubmit}
                disabled={isSubmitting || Object.keys(errors).length}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default PasswordForgotForm;
