import axios from "axios";
import { handleErrorSet } from "../../../utils/fetchUtils";
import Paths from "../../../utils/paths";
import { API_URL, USER_ROLE } from "../../../utils/constants";

export const postLogin = async (body, setErrorStatus, intl, isPublic=false) => {
  try {
    const response = await axios.post(
      `${API_URL}/${Paths.Auth.login}${isPublic ? '?public=1' : ''}`,
      {
        identifier: body.email,
        password: body.password,
      },
    );
    const {
      data: { jwt = "", user = {}, message = [] },
      status = 0,
    } = response || {};
    if (jwt && status >= 200 && status < 300) {
      return { jwt, user };
    } else {
      const [messages = {}] = message;
      const {
        messages: [errorObject],
      } = messages;
      const errorMessage = (errorObject && errorObject.message) || "";
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  } catch (error) {
    const errorTranslationKey = error?.response?.data?.error?.message;
    if (errorTranslationKey) {
      const errorMessage = intl.messages[errorTranslationKey];
      handleErrorSet(error, setErrorStatus, errorMessage);
    } else {
      handleErrorSet(error, setErrorStatus);
    }
  }
};

export const postRegister = async (body, setErrorStatus, intl) => {
  try {
    const response = await axios.post(
      `${API_URL}/${Paths.Auth.register}`,
      {
        name: body.name,
        email: body.email,
        phone: body.phone,
        password: body.password,
        role: USER_ROLE,
      },
    );
    const { data = {}, status = 0 } = response || {};
    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const {
        messages: [errorObject],
      } = messages;
      const errorMessage = (errorObject && errorObject.message) || "";
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  } catch (error) {
    const errorTranslationKey = error?.response?.data?.error?.message;
    if (errorTranslationKey) {
      const errorMessage = intl.messages[errorTranslationKey];
      handleErrorSet(error, setErrorStatus, errorMessage);
    } else {
      handleErrorSet(error, setErrorStatus);
    }
  }
};

export const postForgotPassword = async (body, setErrorStatus) => {
  try {
    const response = await axios.post(
      `${API_URL}/${Paths.Auth.forgotPassword}`,
      {
        email: body.email,
      },
    );
    const { data = {}, status = 0 } = response || {};
    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const {
        messages: [errorObject],
      } = messages;
      const errorMessage = (errorObject && errorObject.message) || "";
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  } catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const postResetPassword = async (body, setErrorStatus) => {
  try {
    const response = await axios.post(
      `${API_URL}/${Paths.Auth.resetPassword}`,
      {
        code: body.code,
        password: body.password,
        passwordConfirmation: body.passwordConfirmation,
      },
    );
    const { data = {}, status = 0 } = response || {};
    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const {
        messages: [errorObject],
      } = messages;
      const errorMessage = (errorObject && errorObject.message) || "";
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  } catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const postResendVerifyMail = async (body, setErrorStatus, intl) => {
  try {
    const response = await axios.post(
      `${API_URL}/${Paths.Auth.resendVerificationMail}`,
      {
        email: body.email,
      },
    );
    const { data = {}, status = 0 } = response || {};
    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const {
        messages: [errorObject],
      } = messages;
      const errorMessage = (errorObject && errorObject.message) || "";
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  } catch (error) {
    const errorTranslationKey = error?.response?.data?.error?.message;
    if (errorTranslationKey) {
      const errorMessage = intl.messages[errorTranslationKey];
      handleErrorSet(error, setErrorStatus, errorMessage);
    } else {
      handleErrorSet(error, setErrorStatus);
    }
  }
};
