import React from 'react';
import _ from 'lodash';
import { AppContext } from '../../../storage/context';
import { TRACKING_EVENT_NAMES } from '../../../utils/constants';
import useSetError from '../../../utils/hooks/useSetError';
import {
  getSubcategoriesHelper,
  getMainCategoriesHelper,
  getToolsHelper,
  getSkillsHelper,
  getLanguagesHelper,
  getCaseCategoriesHelper,
  getCasesHelper,
  getEventTypesHelper,
} from '../../../api/services/Helpers';

const Helper = () => {
  const [{ token = '', helpers = {} }, dispatch] = React.useContext(AppContext);

  const setError = useSetError();

  const fetchHelpers = React.useCallback(async () => {
    if (token) {
      try {
        const [
          mainCategoriesData,
          subcategoriesData,
          toolsData,
          skillsData,
          languagesData,
          caseCategoriesData,
          casesData,
          trackingEventTypesData,
        ] = await Promise.all([
          getMainCategoriesHelper(setError),
          getSubcategoriesHelper(setError),
          getToolsHelper(setError),
          getSkillsHelper(setError),
          getLanguagesHelper(setError),
          getCaseCategoriesHelper(setError),
          getCasesHelper(setError),
          getEventTypesHelper(setError),
        ]);

        const updatedHelpers = _.cloneDeep(helpers);

        updatedHelpers.mainCategories = mainCategoriesData;
        updatedHelpers.subcategories = subcategoriesData;
        updatedHelpers.tools = toolsData;
        updatedHelpers.skills = skillsData;
        updatedHelpers.languages = languagesData;
        updatedHelpers.caseCategories = caseCategoriesData;
        updatedHelpers.cases = casesData;
        updatedHelpers.trackingEventTypes = trackingEventTypesData;

        // Check if used event name constants exist in the helper (and so the database)
        Object.keys(TRACKING_EVENT_NAMES).forEach((eventName) => {
          if (!trackingEventTypesData?.some((eventType) => eventType.name === TRACKING_EVENT_NAMES[eventName])) {
            console.error(
              `Error! An event of TRACKING_EVENT_NAMES named '${TRACKING_EVENT_NAMES[eventName]}' is not present in the database. Please update the database with this event type`
            );
          }
        });

        if (!_.isEqual(helpers, updatedHelpers)) {
          dispatch({
            type: 'setHelpers',
            helpers: updatedHelpers,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch, setError]);

  React.useEffect(() => {
    fetchHelpers();
  }, [fetchHelpers]);

  return null;
};

export default Helper;
