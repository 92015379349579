import qs from 'qs';
import protectedHttp from "../../ProtectedHTTP";
import { handleErrorSet } from "../../../utils/fetchUtils";
import Paths from "../../../utils/paths";

export const getCandidates = async (filterList, setErrorStatus) => {
  try {
    const query = qs.stringify({
      filters: {
        $and: filterList
      }
    });
    const response = await protectedHttp.get(
      `/${Paths.Candidate.candidates}?${query}`,
    );

    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const getCandidatesSearch = async (filterList, setErrorStatus) => {
  try {
    const query = qs.stringify({
      filters: {
        $and: filterList
      }
    });
    const response = await protectedHttp.get(
      `/${Paths.Candidate.candidatesSearch}?${query}`,
    );

    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const getCandidatesCount = async (filterList, setErrorStatus) => {
  try {
    const query = qs.stringify({
      filters: {
        $and: filterList
      }
    });
    const response = await protectedHttp.get(
      `/${Paths.Candidate.candidates}/count?${query}`,
    );

    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const getCandidate = async (candidateId, setErrorStatus) => {
  try {
    const response = await protectedHttp.get(
      `/${Paths.Candidate.candidate}/${candidateId}`,
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const getSavedCandidates = async (setErrorStatus) => {
  try {
    const response = await protectedHttp.get(
      `/${Paths.Candidate.savedCandidates}`
    );

    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const deleteSavedCandidate = async (candidateId, setErrorStatus) => {
  try {
    const response = await protectedHttp.delete(
      `/${Paths.Candidate.savedCandidates}/${candidateId}`
    );

    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};
