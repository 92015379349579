import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PATHS } from '../../../utils/constants';
import useResolveScreen from '../../../utils/hooks/useResolveScreen';
import useUser from '../../../utils/hooks/useUser';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import BackButton from '../../common/ui/Buttons/BackButton';
import Button34 from '../../common/ui/Buttons/Button34';
import ProfileEditForm from '../../forms/ProfileEditForm';
import './index.scss';

const ProfileEditPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const user = useUser(React.useCallback(() => navigate(PATHS.profile), [navigate]));
  const screen = useResolveScreen(BREAKPOINTS.md);

  const initialValues = {
    name: user.user.name || '',
    phone: user.user.phone || '',
    password: '',
    passwordConfirmation: '',
  };

  React.useEffect(() => {
    if (screen) {
      if (screen.isMobile === false) {
        navigate(PATHS.profile);
      }
    }
  }, [navigate, screen]);

  return (
    <div className="profile-edit-page">
      {<div className="profile-edit-page__container">
        <TopMenuLayerMobile />
        <div className="profile-edit-page__title">
          {intl.messages['profile.menu.edit']}
        </div>
        <div className="profile-edit-page__content">
          <ProfileEditForm
            initialValues={initialValues}
            onSubmit={user?.edit}
          />
        </div>
        <div className="profile-edit-page__bottom-section">
          <div className="profile-edit-page__button-container">
            <Button34
              className="profile-edit-page__button"
              label={intl.messages['profile.menu.title']}
              handleClick={() => navigate(PATHS.profile)}
            />
          </div>
          <BackButton className="profile-edit-page__back-button" />
        </div>
      </div>}
    </div>
  );
};

export default ProfileEditPage;
