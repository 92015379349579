import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Button34 = (props) => {
	const {
		label = '',
		type = 'button',
		className = '',
		handleClick = () => { },
		disabled = false,
	} = props;

	return (
		<button
			type={type}
			className={classnames('button34', className)}
			onClick={handleClick}
			disabled={disabled}
      dangerouslySetInnerHTML={{ __html: label }}
		>
		</button>
	)
}

export default Button34;
