import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { TOKEN, USER } from '../../../utils/constants';

export const ProtectedRoute = (props) => {
  const { children } = props;

  const token = sessionStorage.getItem(TOKEN) || '';

  if (!token) {
    return <Navigate replace to="/" />;
  }

  return children ? children : <Outlet />;
};

export const NonGuestRoute = (props) => {
  const { children } = props;

  const user = JSON.parse(sessionStorage.getItem(USER));

  if (!user || user.isGuest) {
    return <Navigate replace to="/" />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
