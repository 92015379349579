import React from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from "react-transition-group";
import { MATCH, TOP } from '../../../utils/constants';
import Button45 from '../ui/Buttons/Button45';
import './index.scss';

const PromptModal = props => {
  const { isVisible, title, description, onAccept, onCancel } = props;

  const nodeRef = React.useRef(null);

  const intl = useIntl();

  return (
    <CSSTransition nodeRef={nodeRef} in={Boolean(isVisible)} timeout={300} classNames="react-transition">
      <div className="prompt-modal" ref={nodeRef}>
        <div className="prompt-modal__container">
          <div className="prompt-modal__backdrop"></div>
          <div className="prompt-modal__content">
            <div className="prompt-modal__title">
              <span className="prompt-modal__title-part -secondary">{TOP}</span>
              <span className="prompt-modal__title-part -primary">{MATCH}</span>
            </div>
            <div className="prompt-modal__body">
              {title && (
                <h3
                  className="prompt-modal__body-title"
                  dangerouslySetInnerHTML={{ __html: title || "" }}
                ></h3>
              )}
              {description && (
                <p
                  className="prompt-modal__body-text"
                  dangerouslySetInnerHTML={{ __html: description || "" }}
                ></p>
              )}
            </div>
            <div className="prompt-modal__footer">
              <Button45
                className="prompt-modal__button -red-dark"
                label={intl.messages['common.cancel']}
                boldLabel
                handleClick={onCancel}
              />
              <Button45
                className="prompt-modal__button -primary"
                label={intl.messages['common.ok']}
                boldLabel
                handleClick={onAccept}
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default PromptModal;
