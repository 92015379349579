import React from 'react';
import * as classnames from 'classnames';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const SelectField = props => {
  const { label, text, className, error, classNamePrefix, id, name, ...other } = props;

  return (
    <div className={classnames('select-field', className)}>
      <div className="select-field__top">
        {
          label &&
            <label
              className="select-field__label"
              htmlFor={id || name}
              dangerouslySetInnerHTML={{ __html: label }}
            >
            </label>
        }
        
        {text && <span className="select-field__text">{text}</span>}
        {error ? <div className="select-field__error">{error}</div> : null}
      </div>
      <Select
        name={id || name}
        className="select-field__input"
        classNamePrefix={`${error ? 'error ' : ''}select ${classNamePrefix}`}
        blurInputOnSelect={false}
        components={{ DropdownIndicator, ClearIndicator }}
        {...other}
      />
    </div>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon
      className="select-field__caret"
      icon={faCaretDown}
    />
  </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon
      className="select-field__clear"
      icon={faXmark}
    />
  </components.ClearIndicator>
);

export default SelectField;
