import React from 'react';
import { useIntl } from 'react-intl';
import { TOP, MATCH } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { AppContext } from '../../../storage/context';
import PasswordForgotForm from '../../forms/PasswordForgotForm';
import LanguageSelectMenu from '../../common/LanguageSelectMenu';
import { postForgotPassword } from '../../../api/services/Auth';
import BackButton from '../../common/ui/Buttons/BackButton';
import './index.scss';

const PasswordForgotPage = () => {
  const dispatch = React.useContext(AppContext)[1];

  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const sendEmail = async (values, { setSubmitting }) => {
    const payload = {
      email: values.email,
    };

    try {
      loadingLayer.show();
      const response = await postForgotPassword(payload, setError);
      
      if (response) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages['modal.forgotPassword.message'],
          },
        });
      }
    } catch(error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }

    setSubmitting(false);
  };

  return (
    <div className="password-forgot-page">
      <div className="password-forgot-page__container">
        <div className="password-forgot-page__content">
          <LanguageSelectMenu
            className="password-forgot-page__language-select-menu"
          />
          <div className="password-forgot-page__title">
            <h1 className="password-forgot-page__title-front">{TOP}</h1>
            <h1 className="password-forgot-page__title-back">{MATCH}</h1>
          </div>
          <h3
            className="password-forgot-page__reset"
            dangerouslySetInnerHTML={{ __html: intl.messages['forgotPassword.requestReset'] }}
          ></h3>
          <p
            className="password-forgot-page__description"
            dangerouslySetInnerHTML={{ __html: intl.messages['forgotPassword.description'] }}
          ></p>
          <PasswordForgotForm onSubmit={sendEmail} />
        </div>
      </div>
      <BackButton />
    </div>
  );
};

export default PasswordForgotPage;
