import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import * as classnames from 'classnames';
import { AppContext } from '../../../../storage/context';
import { PATHS, PROFILE_MENU, PROFILE_SUBPAGES } from '../../../../utils/constants';
import BackButton from '../../../common/ui/Buttons/BackButton';
import Button36 from '../../../common/ui/Buttons/Button36';
import ProfilePageSavedCandidates from './ProfilePageSavedCandidates';
import ProfilePageMyCandidates from './ProfilePageMyCandidates';
import ProfilePageFaq from './ProfilePageFaq';
import ProfilePageEdit from './ProfilePageEdit';
import './index.scss';

const ProfilePageDesktop = props => {
  const [{ user = {} }, dispatch] = React.useContext(AppContext);

  const { className } = props;
  
  const [activeMenuItem, setActiveMenuItem] = React.useState(PROFILE_MENU.find(item => item.order === 1));

  const intl = useIntl();
  const navigate = useNavigate();

  const nullToken = () => {
    dispatch({
      type: 'nullToken',
    });
  };

  const nullUser = () => {
    dispatch({
      type: 'nullUser',
    });
  };
  
  const logOut = () => {
    nullToken();
    sessionStorage.clear();
    localStorage.clear();
    nullUser();
    navigate(PATHS.root);
  };

  const redirectToMenuDefault = React.useCallback(() => {
    setActiveMenuItem(PROFILE_MENU.find(item => item.order === 1));
  }, []);

  const renderSubpage = () => {
    switch(activeMenuItem.content) {
      case PROFILE_SUBPAGES.SAVED_CANDIDATES:
        return <ProfilePageSavedCandidates />;
      case PROFILE_SUBPAGES.MY_CANDIDATES:
        return <ProfilePageMyCandidates />;
      case PROFILE_SUBPAGES.FAQ:
        return <ProfilePageFaq />; 
      case PROFILE_SUBPAGES.EDIT:
        return <ProfilePageEdit redirectToMenuDefault={redirectToMenuDefault} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={classnames(
      'profile-page-desktop',
      className && className,
    )}>
      <div className="profile-page-desktop__menu">
        <h2 className="profile-page-desktop__menu-title">{intl.messages['profile.menu.title']}</h2>
        <p className="profile-page-desktop__detail">{user.name}</p>
        <p className="profile-page-desktop__detail">{user.email}</p>
        <p className="profile-page-desktop__detail">{intl.messages['profile.menu.phoneShort']}: {user.phone}</p>
        <p
          className="profile-page-desktop__edit"
          onClick={() => setActiveMenuItem(PROFILE_MENU.find(item => item.content === PROFILE_SUBPAGES.EDIT))}
        >{intl.messages['profile.menu.edit']}</p>
        <Button36
          className={classnames(
            'profile-page-desktop__button',
            '-primary-dark',
          )}
          label={intl.messages['profile.menu.logout']}
          handleClick={logOut}
        />
        <div className="profile-page-desktop__options">
          {
            PROFILE_MENU.filter(item => item.content !== PROFILE_SUBPAGES.EDIT).map(menuItem => (
              <h3
                key={menuItem.order}
                className={classnames(
                  'profile-page-desktop__option',
                  activeMenuItem.order === menuItem.order && '-active',
                )}
                onClick={() => setActiveMenuItem(menuItem)}
              >
                {intl.messages[menuItem.labelKey]}
              </h3>
            ))
          }
        </div>
      </div>
      <div className="profile-page-desktop__content">
        <h2 className="profile-page-desktop__content-title">{intl.messages[activeMenuItem.labelKey]}</h2>
        {renderSubpage()}
      </div>
      <BackButton />
    </div>
  );
};

export default ProfilePageDesktop;
