import React from 'react';
import { useNavigate } from "react-router-dom";
import * as classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const BackButton = (props) => {
  const { className, disabled } = props;

  const navigate = useNavigate();

  return (
    <div
      className={classnames(
        'back-button',
        className && className,
        disabled && '-disabled',
      )}
      onClick={() => navigate(-1)}
    >
      <div
        className={classnames(
          'back-button__label',
          disabled && '-disabled',
        )}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    </div>
  );
};

export default BackButton;
