import React from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from "react-transition-group";
import { AppContext } from '../../../storage/context';
import { PATHS } from '../../../utils/constants';
import lightbulbsImage from '../../../assets/images/backgrounds/lightbulbs-small.webp';
import Button45 from '../ui/Buttons/Button45';
import './index.scss';

const GuestModal = () => {
  const [{ guestModalData = {} }, dispatch] = React.useContext(AppContext);

  const nodeRef = React.useRef(null);
  const intl = useIntl();
  const showModal = guestModalData?.show;
  const navigate = guestModalData?.navigate;

  const closeModal = () => {
    dispatch({
      type: 'nullGuestModalData',
    });
  };

  const nullToken = () => {
    dispatch({
      type: 'nullToken',
    });
  };

  const nullUser = () => {
    dispatch({
      type: 'nullUser',
    });
  };

  const logOut = () => {
    nullToken();
    sessionStorage.clear();
    localStorage.clear();
    nullUser();
  }

  const goToRegistration = () => {
    logOut();
    navigate(PATHS.register);
    closeModal();
  };

  return (
    <CSSTransition nodeRef={nodeRef} in={Boolean(showModal)} timeout={300} classNames="react-transition">
      <div className="guest-modal" ref={nodeRef}>
        <div className="guest-modal__container">
          <div className="guest-modal__backdrop"></div>
          <div className="guest-modal__content">
            <img className="guest-modal__image" src={lightbulbsImage} alt="illustration" />
            <div className="guest-modal__text-content-wrapper">
              <h2 className="guest-modal__title">{intl.messages['guest.modal.title'] || "Guest Modal"}</h2>
              <div className="guest-modal__body">
                <p
                  className="guest-modal__body-text"
                  dangerouslySetInnerHTML={{__html: intl.messages['guest.modal.body.text'] || ""}}
                ></p>
              </div>
              <div className="guest-modal__footer">
                <Button45
                  className="guest-modal__button -primary"
                  label={intl.messages['guest.modal.button1'] || "Create a new user"}
                  boldLabel
                  handleClick={goToRegistration}
                  />
                <Button45
                  className="guest-modal__button -primary-dark"
                  label={intl.messages['guest.modal.button2'] || "Continue as guest"}
                  boldLabel
                  handleClick={closeModal}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default GuestModal;
