import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../storage/context';
import { PATHS } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { getCase } from '../../../api/services/Cases';
import ScreenResolver from '../../common/ScreenResolver';
import CasePageDesktop from './CasePageDesktop';
import CasePageMobile from './CasePageMobile';
import './index.scss';

const CasePage = () => {
  const { helpers = {} } = React.useContext(AppContext)[0];
  const [caseItem, setCaseItem] = React.useState(null);

  const { caseSlug } = useParams();
  const { cases } = helpers;
  const navigate = useNavigate();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const navigateToCandidateList = () => {
    navigate(`${PATHS.candidatesByCase}/${caseSlug}`);
  };

  React.useEffect(() => {
    const fetchCase = async () => {
      try {
        loadingLayer.show();
        const caseId = cases?.find(caseItem => caseItem.slug === caseSlug)?.id;
        if (caseId) {
          const caseData = await getCase(caseId, setError);
          if (caseData) {
            setCaseItem(caseData);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingLayer.hide();
      }
    };

    fetchCase();
  }, [loadingLayer, setError, caseSlug, cases]);

  return (
    <div className="case-page">
      <ScreenResolver
        large={BREAKPOINTS.md}
        desktop={
          <CasePageDesktop
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        }
        mobile={
          <CasePageMobile
            caseItem={caseItem}
            navigateToCandidateList={navigateToCandidateList}
          />
        }
      />
    </div>
  );
};

export default CasePage;
