export const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= window.innerHeight &&
      rect.right <= window.innerWidth
  );
};

export const isInCandidatePageViewport = element => {
  const vhInPx = 1 / (window.innerHeight / 100);
  const headerHeight = 18.52 * vhInPx + 5;
  const footerHeight = 14.26 * vhInPx + 5;

  const rect = element.getBoundingClientRect();
  return (
      rect.top >= headerHeight &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight - footerHeight) &&
      rect.right <= window.innerWidth
  );
}

export const createMainCategoriesSelectOptions = (mainCategories, selectedLanguageCode) => {
  return mainCategories
    ?.sort((category1, category2) => category1.order - category2.order)
    .map(category => {
      return {
        value: category.id,
        label: category[selectedLanguageCode].title,
      };
    }) || [];
};

export const createSubcategoriesSelectOptions = (subcategories, selectedMainCategory, selectedLanguageCode) => {
  return subcategories
    ?.filter(subcategory => selectedMainCategory ? selectedMainCategory.subcategories?.includes(subcategory.id) : true)
    .sort((category1, category2) => category1.order - category2.order)
    .map(category => {
      return {
        value: category.id,
        label: category[selectedLanguageCode].title,
      };
    }) || [];
};

export const createToolsSelectOptionsWithoutLevels = (tools, values) => {
  const selectedToolGroupIds = values?.tools?.map(tool => tool?.value) || [];
  const groupIds = [];

  return tools?.filter(tool => {
    if (!groupIds.includes(tool.groupId)) {
      groupIds.push(tool.groupId);
      return true;
    }
    return false;
  })
  .map(tool => {
    return {
      value: tool.groupId,
      label: tool.name,
    };
  })
  .filter(tool => !selectedToolGroupIds.includes(tool.value))
  .sort((a, b) => a.label.localeCompare(b.label)) || [];
};

export const createSkillsWithoutLevels = (skills, selectedSubcategory) => {
  if (!selectedSubcategory) {
    return [];
  }

  const groupIds = [];

  return skills?.filter(skill => skill.subcategories?.includes(selectedSubcategory?.value))
  .filter(skill => {
    if (!groupIds.includes(skill.groupId)) {
      groupIds.push(skill.groupId);
      return true;
    }
    return false;
  })
  .map(skill => {
    return {
      ...skill,
      isChecked: false,
    };
  }) || [];
};

export const createSkillsSelectOptionsWithoutLevelsAlphabetic = (skills, selectedLanguageCode) => {
  const groupIds = [];

  return skills?.filter(skill => {
    if (!groupIds.includes(skill.groupId)) {
      groupIds.push(skill.groupId);
      return true;
    }
    return false;
  })
  .map(skill => {
    return {
      value: skill.groupId,
      label: skill[selectedLanguageCode].name,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label)) || [];
};

export const createQueryFilterList = searchData => {
  const mainCategoryFilter = searchData.mainCategory?.value || null;
  const subcategoryFilter = searchData.subcategory?.value || null;
  const caseFilter = searchData.case?.value || null;
  const toolsValues = searchData.tools?.length > 1 ? (
    searchData.tools
    .filter(tool => tool !== null)
    .map(tool => tool.value)
  ) : null;
  const toolsFilters = toolsValues ? (
    toolsValues.map(toolValue => ({
      tools: {
        group_id: {
          $eq: toolValue,
        },
      },
    }))
  ) : [];
  const skillsValues = searchData.skills?.some(skill => skill.isChecked) ? (searchData.skills
    .filter(skill => skill.isChecked)
    .map(skill => skill.groupId)
  ) : [];
  const allSkillsValues = searchData.allSkills?.length ? (
    searchData.allSkills.map(skill => skill.value)
  ) : [];
  const unitedSkillsValues = skillsValues.length || allSkillsValues.length ? skillsValues.concat(allSkillsValues) : null;
  const unitedSkillsFilters = unitedSkillsValues ? (
    unitedSkillsValues.map(skillValue => ({
      skills: {
        group_id: {
          $eq: skillValue,
        },
      },
    }))
  ) : [];

  const filterList = [
    {
      active: {
        $eq: true,
      }
    },
    (mainCategoryFilter ?
      {
        main_categories: {
          id: {
            $eq: mainCategoryFilter,
          },
        },
      } :
      null),
    (subcategoryFilter ?
      {
        subcategories: {
          id: {
            $eq: subcategoryFilter,
          },
        },
      } :
      null),
    (caseFilter ?
      {
        cases: {
          id: {
            $eq: caseFilter,
          },
        },
      } :
      null),
    ...toolsFilters,
    ...unitedSkillsFilters,
  ].filter(elem => elem !== null);

  return filterList;
};

export const getUrlParams = search => {
  if (!search || typeof search !== 'string') {
    return {};
  }
  
  const searchParams = {};
  search
    .split('')
    .filter((elem, i) => i !== 0)
    .join('')
    .split('&')
    .forEach(param => {
      const [key, value] = param.split('=');
      searchParams[key] = value;
    });

    return searchParams;
};

export const comparePrimitiveObjectsWithSameStructure = (object1, object2) => {
  if (!object1 || !object2) {
    return false;
  }
  if (Object.keys(object1).length !== Object.keys(object2).length) {
    return false;
  }

  for (const key of Object.keys(object1)) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export const objectHasOnlyFalsyValues = object => {
  if (!object) {
    return false;
  }
  
  Object.keys(key => {
    if (object[key]) {
      return false;
    }
  });

  return true;
};

export const createDoubleDigitPositiveIntegerString = inputNumber => {
  if (!inputNumber) {
    return null;
  }

  const number = Number(inputNumber);
  if (!number || number < 0 || number >= 100 || number % 1) {
    return null;
  }

  if (number >= 0 && number < 10) {
    return `0${number}`;
  } else {
    return `${number}`;
  }
};
