import React from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from "react-transition-group";
import * as classnames from 'classnames';
import LevelIndicator from '../LevelIndicator';
import Button45 from '../ui/Buttons/Button45';
import './index.scss';

const DotDescriptionModal = props => {
  const {
    className,
    show,
    closeModal,
  } = props;

  const nodeRef = React.useRef(null);

  const intl = useIntl();

  const levelDescription = [
    {
      level: 1,
      description: intl.messages['modals.dotDescription.level1'],
    },
    {
      level: 2,
      description: intl.messages['modals.dotDescription.level2'],
    },
    {
      level: 3,
      description: intl.messages['modals.dotDescription.level3'],
    },
    {
      level: 4,
      description: intl.messages['modals.dotDescription.level4'],
    },
    {
      level: 5,
      description: intl.messages['modals.dotDescription.level5'],
    },
  ];

  return (
    <CSSTransition nodeRef={nodeRef} in={Boolean(show)} timeout={300} classNames="react-transition">
      <div
        className={classnames(
          'dot-description-modal',
          className && className,
        )}
        ref={nodeRef}
      >
        <h2 className="dot-description-modal__title">
          {intl.messages['modals.dotDescription.title']}
        </h2>
        <div className="dot-description-modal__content">
          {levelDescription.map(level => (
            <div
              key={level.level}
              className="dot-description-modal__row"
            >
              <LevelIndicator
                className="dot-description-modal__indicator"
                level={level.level}
                sizePx={20}
              />
              <p className="dot-description-modal__description">
                {level.description}
              </p>
            </div>
          ))}
        </div>
        <div className="dot-description-modal__button-container">
          <Button45
            className="dot-description-modal__button -primary-dark"
            label={intl.messages['common.ok']}
            handleClick={closeModal}
            boldLabel
          />
        </div>
      </div>
    </CSSTransition>
  );
};

export default DotDescriptionModal;
