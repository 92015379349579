import React from 'react';
import useSetError from '../../../utils/hooks/useSetError';
import { postUserSessionTracking } from '../../../api/services/Tracking';
import { AppContext } from '../../../storage/context';

const UserSessionHandler = () => {
  const { token = '', user = null} = React.useContext(AppContext)[0];
  const [sessionInterval, setSessionInterval] = React.useState(null);

  const setError = useSetError();

  React.useEffect(() => {
    if (token && user && !sessionInterval) {
      const interval = setInterval(async () => {
        try {
          await postUserSessionTracking({ userId: user.id }, setError);
        } catch (err) {
          console.error(err);
        }
      }, 60000);

      if (!sessionInterval) {
        setSessionInterval(interval);
      }
    }

    if ((!token || !user) && sessionInterval) {
      clearInterval(sessionInterval);
      setSessionInterval(null);
    }

  }, [token, user, sessionInterval, setError]);

  return null;
};

export default UserSessionHandler;
