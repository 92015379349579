import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { resendVerifyMailSchema } from '../../../utils/validationSchemas';
import { PATHS } from '../../../utils/constants';
import InputTextField from '../../common/ui/InputTextField';
import Button from '../../common/ui/Buttons/Button';

const ResendVerifyMailForm = (props) => {
  const { onSubmit } = props;

  const navigate = useNavigate();
  const intl = useIntl();

  const initialValues = {
    email: '',
  };

  const ERROR_MESSAGES = {
    EMAIL_EMPTY: intl.messages['error.emailEmpty'],
    EMAIL_FORMAT: intl.messages['error.emailInvalid'],
    REQUIRED_FIELD: intl.messages['error.fieldRequired'],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resendVerifyMailSchema(ERROR_MESSAGES)}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({ values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
        <form className="resend-verify-mail-page__form" onSubmit={handleSubmit}>
          <InputTextField
            className="resend-verify-mail-page__form-input"
            placeholder={intl.messages['common.email']}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
          />
          <div className="resend-verify-mail-page__buttons">
            <Button
                className='resend-verify-mail-page__button -send'
                label={intl.messages['common.send']}
                type='submit'
                onSubmit={handleSubmit}
                disabled={isSubmitting || Object.keys(errors).length}
            />
            <Button
                className='resend-verify-mail-page__button -login -secondary'
                label={intl.messages['common.toLogin']}
                handleClick={() => {
                  navigate(PATHS.login);
                }}
            />
            <Button
                className='resend-verify-mail-page__button -register -secondary'
                label={intl.messages['common.toRegister']}
                handleClick={() => {
                  navigate(PATHS.register);
                }}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default ResendVerifyMailForm;
