import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../storage/context';
import { MATCH, PATHS, TOP } from '../../../utils/constants';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import ScreenResolver from '../ScreenResolver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const TopMenuLayerMobile = () => {
  const [{ user = {} }, dispatch] = React.useContext(AppContext);

  const navigate = useNavigate();

  const handleProfileClick = () => {
    if (!user.isGuest) {
      navigate(PATHS.profile);
      return;
    }
    dispatch({
      type: 'setGuestModalData',
      guestModalData: {
        show: true,
        navigate: navigate
      },
    });
  };

  return (
    <ScreenResolver
      large={BREAKPOINTS.md}
      desktop={null}
      mobile={
        <div className="top-menu-layer-mobile">
          <div
            className="top-menu-layer-mobile__button-container"
            onClick={() => navigate(PATHS.menu)}
          >
            <FontAwesomeIcon icon={faBars} className="top-menu-layer-mobile__button" />
          </div>
          <ScreenResolver
            large={BREAKPOINTS.md}
            desktop={null}
            mobile={
              <div className="top-menu-layer-mobile__title">
                <h1 className="top-menu-layer-mobile__title-front">{TOP}</h1>
                <h1 className="top-menu-layer-mobile__title-back">{MATCH}</h1>
              </div>
            }
          />
          <div
            className="top-menu-layer-mobile__button-container"
            onClick={handleProfileClick}
          >
            <FontAwesomeIcon icon={faUser} className="top-menu-layer-mobile__button" />
          </div>
        </div>
      }
    />
  );
};

export default TopMenuLayerMobile;
