import React from 'react';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import Slider from 'react-slick';
import { API_BASE_URL, LANGUAGE, LANGUAGES } from '../../../../../utils/constants';
import useSliderSettings from '../../hooks';
import Button34 from '../../../../common/ui/Buttons/Button34';
import palceholderBackground from '../../../../../assets/images/backgrounds/placeholder.png';
import './index.scss';

const CasePageMobileCase = props => {
  const { className, caseItem, navigateToCandidateList } = props;

  const intl = useIntl();
  const sliderSettings = useSliderSettings();

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  return (
    <div className={classnames(
      'case-page-mobile-case',
      className && className,
    )}>
      <div className="case-page-mobile-case__gallery">
        {caseItem.caseImages?.length ? (
          <Slider { ...sliderSettings.mobile }>
            {caseItem.caseImages?.map((image, i) => (
              <div key={i} className="case-page-mobile-case__image-container">
                <img
                  className="case-page-mobile-case__image"
                  src={`${API_BASE_URL}/${image.url}` || palceholderBackground}
                  alt="case"
                />
              </div>
            ))}
          </Slider>
        ) : (
          null
        )}
      </div>
      <div className="case-page-mobile-case__text">
        <p
          className="case-page-mobile-case__title"
          dangerouslySetInnerHTML={{ __html: caseItem[selectedLanguageCode]?.caseHeader }}
        ></p>
        <p
          className="case-page-mobile-case__description"
          dangerouslySetInnerHTML={{ __html: caseItem[selectedLanguageCode]?.caseDescription }}
        ></p>
        <Button34
          className="case-page-mobile-case__button-candidates -black"
          label={intl.messages['case.seeCandidates']}
          handleClick={navigateToCandidateList}
          disabled={!caseItem.candidates?.length}
        />
      </div>
    </div>
  );
};

export default CasePageMobileCase;
