import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PATHS } from '../../../utils/constants';
import useSavedCandidates from '../../../utils/hooks/useSavedCandidates';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useResolveScreen from '../../../utils/hooks/useResolveScreen';
import CandidateCardMobile from '../../common/CandidateCardMobile';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import BackButton from '../../common/ui/Buttons/BackButton';
import Button34 from '../../common/ui/Buttons/Button34';
import './index.scss';

const ProfileSavedCandidatesPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const savedCandidates = useSavedCandidates();
  const screen = useResolveScreen(BREAKPOINTS.md);

  const navigateToCandidate = candidateId => {
    const path = `${PATHS.candidateBySearch}/${candidateId}`;
    navigate(path);
  };

  React.useEffect(() => {
    if (screen) {
      if (screen.isMobile === false) {
        navigate(PATHS.profile);
      }
    }
  }, [navigate, screen]);

  return (
    <div className="profile-saved-candidates-page">
      <div className="profile-saved-candidates-page__container">
        <TopMenuLayerMobile />
        <div className="profile-saved-candidates-page__title">
          {intl.messages['profile.menu.savedCandidates']}
        </div>
        <div className="profile-saved-candidates-page__content">
          {savedCandidates.list?.length ? (
            savedCandidates.list.map(candidate => (
              <CandidateCardMobile
                key={candidate.id}
                className="profile-saved-candidates-page__card-mobile"
                candidate={candidate}
                navigateToCandidate={navigateToCandidate}
                removeCandidate={savedCandidates.remove}
              />
            ))) : null
          }
        </div>
        <div className="profile-saved-candidates-page__bottom-section">
          <div className="profile-saved-candidates-page__button-container">
            <Button34
              className="profile-saved-candidates-page__button"
              label={intl.messages['profile.menu.title']}
              handleClick={() => navigate(PATHS.profile)}
            />
          </div>
          <BackButton className="profile-saved-candidates-page__back-button" />
        </div>
      </div>
    </div>
  );
};

export default ProfileSavedCandidatesPage;
