import React from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from "react-transition-group";
import { AppContext } from '../../../storage/context';
import Button45 from '../ui/Buttons/Button45';
import './index.scss';

const ErrorModal = () => {
  const [{ error = {} }, dispatch] = React.useContext(AppContext);
  const [currentErrorModalData, setCurrentErrorModalData] = React.useState({});

  const nodeRef = React.useRef(null);

  const intl = useIntl();

  const showModal = !!error?.text;

  const closeModal = () => {
    dispatch({
      type: 'nullError',
    });
  };

  React.useEffect(() => {
    const modalNotEmpty = !!error?.text;

    if (modalNotEmpty) {
      setCurrentErrorModalData(error);
    }
  }, [error]);

  return (
    <CSSTransition nodeRef={nodeRef} in={Boolean(showModal)} timeout={300} classNames="react-transition">
      <div className="error-modal" ref={nodeRef}>
        <div className="error-modal__container">
          <div className="error-modal__backdrop"></div>
          <div className="error-modal__content">
            <h2 className="error-modal__title">{intl.messages['modal.error.title'] || "Error"}</h2>
            <div className="error-modal__body">
              {currentErrorModalData.text && (
                <p
                  className="error-modal__body-text"
                  dangerouslySetInnerHTML={{ __html: currentErrorModalData.text || "" }}
                ></p>
              )}
            </div>
            <div className="error-modal__footer">
              <Button45
                className="error-modal__button -primary-dark"
                label={intl.messages['common.ok'] || "Ok"}
                boldLabel
                handleClick={closeModal}
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ErrorModal;
