import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../../storage/context';
import { PATHS } from '../../../utils/constants';
import Button from '../../common/ui/Buttons/Button';
import './index.scss';

const RegisterSuccessPage = () => {
  const dispatch = React.useContext(AppContext)[1];

  const intl = useIntl();
  const navigate = useNavigate();

  React.useEffect(() => {
    const nullToken = () => {
      dispatch({
        type: 'nullToken',
      });
    };
  
    const nullUser = () => {
      dispatch({
        type: 'nullUser',
      });
    };
    
    sessionStorage.clear();
    localStorage.clear();
    nullToken();
    nullUser();
  }, [dispatch]);

  return (
    <div className="register-success-page">
      <div className="register-success-page__container">
        <div className="register-success-page__content">
          <div className="register-success-page__title">
            <h1
              className="register-success-page__title-front"
              dangerouslySetInnerHTML={{ __html: intl.messages["register.successMessage1Part1"] }}
            ></h1>
            <h1 className="register-success-page__title-front">{'\u00A0'}</h1>
            <h1
              className="register-success-page__title-center"
              dangerouslySetInnerHTML={{ __html: intl.messages["register.successMessage1Part2"] }}
            ></h1>
            <h1 className="register-success-page__title-center">{'\u00A0'}</h1>
            <h1
              className="register-success-page__title-back"
              dangerouslySetInnerHTML={{ __html: intl.messages["register.successMessage1Part3"] }}
            ></h1>
          </div>
          <div className="register-success-page__descriptions">
            <p
              className="register-success-page__description"
              dangerouslySetInnerHTML={{ __html: intl.messages["register.successMessage2"] }}
            ></p>
            <p
              className="register-success-page__description"
              dangerouslySetInnerHTML={{ __html: intl.messages["register.successMessage4"] }}
            ></p>
            <p
              className="register-success-page__description"
              dangerouslySetInnerHTML={{ __html: intl.messages["register.successMessage3"] }}
            ></p>
          </div>
          <Button
            className='register-success-page__button'
            label={intl.messages['common.ok']}
            handleClick={() => navigate(PATHS.login)}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccessPage;
