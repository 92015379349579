import { useContext, useEffect } from 'react';
import { AppContext } from './context';
import { TOKEN, USER } from '../utils/constants';

const StorageHandler = () => {
  const [{ token = '', user = null, error = {}, isSet = false }, dispatch] = useContext(AppContext);

  useEffect(() => {
    const localToken = sessionStorage.getItem(TOKEN) || '';
    if (error && error.code && (error.code === 401 || error.code === 403)) {
      sessionStorage.removeItem(TOKEN);
      dispatch({ type: 'nullToken', token: '' });
    }
    if (localToken && !token) {
      dispatch({
        type: 'setToken',
        token: localToken,
      });
    }
  }, [token, error, isSet, dispatch]);

  useEffect(() => {
    const localUser = JSON.parse(sessionStorage.getItem(USER)) || null;
    if (localUser && !user) {
      dispatch({
        type: 'setUser',
        user: localUser,
      });
    }
  }, [user, dispatch]);

  return null;
}

export default StorageHandler;
