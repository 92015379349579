export const BREAKPOINTS = {
  xxs: 360,
  xs: 425,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1900,
};
