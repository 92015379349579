import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../storage/context';
import {
  TOP,
  MATCH,
  PATHS,
  MY_CANDIDATE_STEPS,
  USER_ROLES,
  TRACKING_EVENT_NAMES,
  LANGUAGES,
  LANGUAGE,
  API_BASE_URL,
} from '../../../utils/constants';
import { getIntroVideo } from '../../../api/services/IntroVideo';
import { postTrackingEvent } from '../../../api/services/Tracking';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import ScreenResolver from '../../common/ScreenResolver';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import MenuButtonBig from '../../common/ui/Buttons/MenuButtonBig';
import VideoButton from '../../common/ui/Buttons/VideoButton';
import LogoutButton from '../../common/ui/Buttons/LogoutButton';
import menuPageBackground from '../../../assets/images/backgrounds/language.png';
import supervisorImage from '../../../assets/images/backgrounds/supervisor-small.webp';
import turnerImage from '../../../assets/images/backgrounds/turner-small.webp';
import lightbulbsImage from '../../../assets/images/backgrounds/lightbulbs-small.webp';
import engineerImage from '../../../assets/images/backgrounds/engineer-small.webp';
import './index.scss';

const MainMenuPage = () => {
  const [{ user = {}, helpers = {} }, dispatch] = React.useContext(AppContext);

  const navigate = useNavigate();
  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { trackingEventTypes } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const navigateToSubmenu = async (navigateTo, trackingEventType=null) => {
    if (trackingEventType) {
      const eventId = trackingEventTypes?.find(eventType => eventType.name === trackingEventType)?.id;

      if (user.id && eventId) {
        try {
          loadingLayer.show();

          const payload = {
            userId: user.id,
            trackingEventTypeId: eventId,
          };

          await postTrackingEvent(payload, setError);
        } catch (err) {
          console.error(err);
        } finally {
          loadingLayer.hide();
        }
      }
    }

    navigate(navigateTo);
  };

  const showVideo = async () => {
    try {
      loadingLayer.show();
      const video = await getIntroVideo();

      if (video) {
        dispatch({
          type: 'setVideoModalData',
          videoModalData: {
            url: `${API_BASE_URL}${video[selectedLanguageCode]?.url}`,
          }
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      loadingLayer.hide();
    }
  };

  React.useEffect(() => {
    document.body.style.zoom = 1.0;
  }, []);

  return (
    <div className="main-menu-page">
      <div className="main-menu-page__container">
        <TopMenuLayerMobile />
        <div className="main-menu-page__content">
          <ScreenResolver
            large={BREAKPOINTS.md}
            desktop={
              <div className="main-menu-page__text">
                <div className="main-menu-page__title">
                  <h1 className="main-menu-page__title-front">{TOP}</h1>
                  <h1 className="main-menu-page__title-back">{MATCH}</h1>
                </div>
                <div className="main-menu-page__descriptions">
                  <p
                    className="main-menu-page__description"
                    dangerouslySetInnerHTML={{ __html: intl.messages['mainMenu.description1'] }}
                  ></p>
                  <p
                    className="main-menu-page__description"
                    dangerouslySetInnerHTML={{ __html: intl.messages['mainMenu.description2'] }}
                  ></p>
                </div>
                <VideoButton
                  className="main-menu-page__video-button"
                  label={intl.messages["mainMenu.videoButton"]}
                  handleClick={showVideo}
                />
              </div>
            }
            mobile={null}
          />
          <div className="main-menu-page__menu">
            <MenuButtonBig
              className="main-menu-page__menu-button"
              title={intl.messages["mainMenu.button1Title"]}
              description={intl.messages["mainMenu.button1Description"]}
              image={supervisorImage}
              handleClick={() => navigateToSubmenu(
                PATHS.categories,
                TRACKING_EVENT_NAMES.MAIN_MENU_CANDIDATES_CLICK,
              )}
            />
            <MenuButtonBig
              className="main-menu-page__menu-button"
              title={intl.messages["mainMenu.button2Title"]}
              description={intl.messages["mainMenu.button2Description"]}
              image={turnerImage}
              handleClick={() => navigateToSubmenu(
                PATHS.candidateSearch,
                TRACKING_EVENT_NAMES.MAIN_MENU_COMPETENCE_CLICK,
              )}
              reversedLayout
            />
            <MenuButtonBig
              className="main-menu-page__menu-button"
              title={intl.messages["mainMenu.button3Title"]}
              description={intl.messages["mainMenu.button3Description"]}
              image={lightbulbsImage}
              handleClick={() => navigateToSubmenu(
                PATHS.cases,
                TRACKING_EVENT_NAMES.MAIN_MENU_CASES_CLICK,
              )}
            />
            {user.role?.toUpperCase() === USER_ROLES.ADMIN.toUpperCase() ? (
              <MenuButtonBig
                className="main-menu-page__menu-button"
                title={intl.messages["mainMenu.button4Title"]}
                description={intl.messages["mainMenu.button4Description"]}
                image={engineerImage}
                handleClick={() => navigateToSubmenu(
                  `${PATHS.myCandidate}/${MY_CANDIDATE_STEPS.find(step => step.order === 1)?.slug}`,
                )}
                reversedLayout
              />
            ) : (
              null
            )}
          </div>
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <LogoutButton className="main-menu-page__logout-button"/>
          }
          mobile={
            <div className="main-menu-page__bottom-section">
              <LogoutButton className="main-menu-page__logout-button"/>
            </div>
          }
        />
        <img className="main-menu-page__background" src={menuPageBackground} alt="main-menu-background" />
      </div>
      <div className="main-menu-page__background-layer" />
    </div>
  );
};

export default MainMenuPage;
