import React from 'react';
import * as classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const CloseButtonBig = props => {
  const {
    className,
    handleClick,
  } = props;

  return (
    <div
      className={classnames(
        'close-button-big',
        className && className,
      )}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faXmark} className="close-button-big__icon" />
    </div>
  );
};

export default CloseButtonBig;
