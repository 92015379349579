import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ContextProvider } from './storage/context';
import { reducer } from './storage/reducers/reducer';
import { PATHS } from './utils/constants';
import Internationalization from './components/common/Internationalization';
import ProtectedRoute, { NonGuestRoute } from './components/common/Routing';
import TopMenuLayer from './components/common/TopMenuLayer';
import StorageHandler from './storage/StorageHandler';
import UserSessionHandler from './components/common/UserSessionHandler';
import RegisterPage from './components/pages/RegisterPage';
import RegisterSuccessPage from './components/pages/RegisterSuccessPage';
import PasswordForgotPage from './components/pages/PasswordForgotPage';
import PasswordResetPage from './components/pages/PasswordResetPage';
import ResendVerifyMailPage from './components/pages/ResendVerifyMailPage';
import LoginPage from './components/pages/LoginPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
import ProfilePage from './components/pages/ProfilePage';
import ProfileEditPage from './components/pages/ProfileEditPage';
import ProfileSavedCandidatesPage from './components/pages/ProfileSavedCandidatesPage';
import ProfileMyCandidatesPage from './components/pages/ProfileMyCandidatesPage';
import ProfileFaqPage from './components/pages/ProfileFaqPage';
import MainMenuPage from './components/pages/MainMenuPage';
import MainCategoryPage from './components/pages/MainCategoryPage';
import SubCategoryPage from './components/pages/SubCategoryPage';
import CandidatesCategoryPage from './components/pages/CandidatesCategoryPage';
import CandidateByCategoryPage from './components/pages/CandidateByCategoryPage';
import CandidatesSearchPage from './components/pages/CandidatesSearchPage';
import CandidatesSearchResultPage from './components/pages/CandidatesSearchResultPage';
import CandidateBySearchPage from './components/pages/CandidateBySearchPage';
import CaseCategoryPage from './components/pages/CaseCategoryPage';
import CaseMenuPage from './components/pages/CaseMenuPage';
import CasePage from './components/pages/CasePage';
import CandidatesByCasePage from './components/pages/CandidatesByCasePage';
import CandidateByCasePage from './components/pages/CandidateByCasePage';
import MyCandidatePage from './components/pages/MyCandidatePage';
import Error404Page from './components/pages/Error404Page';
import Helper from './components/common/Helper';
import Modal from './components/common/Modal';
import ErrorModal from './components/common/ErrorModal';
import FullScreenPromptModal from './components/common/FullScreenPromptModal';
import VideoModal from './components/common/VideoModal';
import NoRotateModal from './components/common/NoRotateModal';
import LoadingLayer from './components/common/LoadingLayer';
import GuestModal from './components/common/GuestModal';

const App = () => {
  const {
    root,
    notFound,
    register,
    registerSuccess,
    forgotPassword,
    resetPassword,
    resendVerifyMail,
    login,
    privacyPolicy,
    profile,
    profileSubroutes: {
      edit,
      savedCandidates,
      myCandidates,
      faq,
    },
    menu,
    categories,
    candidateByCategory,
    candidateSearch,
    searchResult,
    candidateBySearch,
    cases,
    candidatesByCase,
    candidateByCase,
    myCandidate,
    params: {
      mainCategorySlug,
      subcategorySlug,
      candidateId,
      myCandidateStep,
      caseCategorySlug,
      caseSlug,
      caseSubpageSlug,
    },
  } = PATHS;

  return (
    <ContextProvider
      initialState={{ error: null, intl: {}, }}
      reducer={reducer}
    >
      <Internationalization>
        <Router>
          <TopMenuLayer />
          <Routes>
            <Route path={notFound} exact element={<Error404Page />} />
            <Route path={login} exact element={<LoginPage />} />
            <Route path={register} exact element={<RegisterPage />} />
            <Route path={registerSuccess} exact element={<RegisterSuccessPage />} />
            <Route path={forgotPassword} exact element={<PasswordForgotPage />} />
            <Route path={resetPassword} exact element={<PasswordResetPage />} />
            <Route path={resendVerifyMail} exact element={<ResendVerifyMailPage />} />
            <Route path={privacyPolicy} exact element={<PrivacyPolicyPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path={root} exact element={<NonGuestRoute />} >         
                <Route path={profile} exact element={<ProfilePage />} />
                <Route path={`${profile}${edit}`} exact element={<ProfileEditPage />} />
                <Route path={`${profile}${savedCandidates}`} exact element={<ProfileSavedCandidatesPage />} />
                <Route path={`${profile}${myCandidates}`} exact element={<ProfileMyCandidatesPage />} />
                <Route path={`${profile}${faq}`} exact element={<ProfileFaqPage />} />
                <Route path={`${myCandidate}${myCandidateStep}`} exact element={<MyCandidatePage />} />
              </Route>
              <Route path={menu} exact element={<MainMenuPage />} />
              <Route path={categories} exact element={<MainCategoryPage />} />
              <Route path={`${categories}${mainCategorySlug}`} exact element={<SubCategoryPage />} />
              <Route path={`${categories}${mainCategorySlug}${subcategorySlug}`} exact element={<CandidatesCategoryPage />} />
              <Route path={`${candidateByCategory}${candidateId}`} exact element={<CandidateByCategoryPage />} />
              <Route path={candidateSearch} exact element={<CandidatesSearchPage />} />
              <Route path={searchResult} exact element={<CandidatesSearchResultPage />} />
              <Route path={`${candidateBySearch}${candidateId}`} exact element={<CandidateBySearchPage />} />
              <Route path={cases} exact element={<CaseCategoryPage />} />
              <Route path={`${cases}${caseCategorySlug}`} exact element={<CaseMenuPage />} />
              <Route path={`${cases}${caseCategorySlug}${caseSlug}${caseSubpageSlug}`} exact element={<CasePage />} />
              <Route path={`${candidatesByCase}${caseSlug}`} exact element={<CandidatesByCasePage />} />
              <Route path={`${candidateByCase}${candidateId}`} exacr element={<CandidateByCasePage />} />
            </Route>
            <Route index path={root} exact element={<LoginPage />} />
            <Route path="*" element={<Navigate replace to={notFound} />} />
          </Routes>
        </Router>
        <Modal />
        <GuestModal />
        <ErrorModal />
        <FullScreenPromptModal />
        <VideoModal />
        <NoRotateModal />
        <LoadingLayer />
        <Helper />
        <StorageHandler />
        <UserSessionHandler />
      </Internationalization>
    </ContextProvider>
  );
};

export default App;
