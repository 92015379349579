import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const ToTopButton = (props) => {
  const { className, disabled, handleClick = () => {} } = props;

  return (
    <div
      className={classnames(
        'to-top-button',
        className && className,
        disabled && '-disabled',
      )}
      onClick={handleClick}
    >
      <div
        className={classnames(
          'to-top-button__label',
          disabled && '-disabled',
        )}
      />
    </div>
  );
};

export default ToTopButton;
