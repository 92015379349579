import React from 'react';
import { AppContext } from '../../storage/context';
import useLoadingLayer from './useLoadingLayer';
import useSetError from './useSetError';
import { deleteDreamCandidate, getDreamCandidates } from '../../api/services/User';

const useMyCandidates = () => {
  const [{ myCandidateList }, dispatch] = React.useContext(AppContext);

  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const deleteMyCandidate = React.useCallback(async (candidateId) => {
    try {
      loadingLayer.show();
      const response = await deleteDreamCandidate(candidateId, setError);
      if (response?.id) {
        const myCandidateListData = await getDreamCandidates(setError);
        
        if (myCandidateListData?.length >= 0) {
          dispatch({
            type: 'setMyCandidateList',
            myCandidateList: myCandidateListData,
          });
        }
      }
    } catch(err) {
      console.error(err);
    } finally {
      loadingLayer.hide();
    }
  }, [dispatch, loadingLayer, setError]);

  React.useEffect(() => {
    const getMyCandidateList = async () => {
      try {
        loadingLayer.show();
        const myCandidateListData = await getDreamCandidates(setError);
        
        if (myCandidateListData?.length >= 0) {
          dispatch({
            type: 'setMyCandidateList',
            myCandidateList: myCandidateListData,
          });
        }
      } catch(err) {
        console.error(err);
      } finally {
        loadingLayer.hide();
      }
    };

    getMyCandidateList();
  }, [dispatch, loadingLayer, setError]);

  return React.useMemo(() => (
    { list: myCandidateList, delete: deleteMyCandidate }
  ), [myCandidateList, deleteMyCandidate]);
};

export default useMyCandidates;
