import React from 'react';
import { AppContext } from '../../storage/context';

const useSetError = () => {
  const [, dispatch] = React.useContext(AppContext);

  const set = React.useCallback(error => {
    dispatch({
      type: 'setError',
      error,
    });
  }, [dispatch]);

  return set;
};

export default useSetError;
