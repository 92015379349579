import React from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../storage/context';
import { USER } from '../constants';
import useLoadingLayer from './useLoadingLayer';
import useSetError from './useSetError';
import { putEditProfile } from '../../api/services/User';

const useUser = (redirect=null) => {
  const dispatch = React.useContext(AppContext)[1];

  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const user = React.useMemo(() => {
    return JSON.parse(sessionStorage.getItem(USER));
  }, []);

  const editData = React.useCallback(async (values, { setSubmitting }) => {
    const profileData = {
      name: values.name,
      phone: values.phone,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    };

    Object.keys(profileData).forEach(key => {
      if (!profileData[key]) {
        delete profileData[key];
      }
    });

    try {
      loadingLayer.show();
      const updatedUser = await putEditProfile(profileData, setError, intl);
      if (updatedUser?.id) {
        dispatch({
          type: 'setUser',
          user: updatedUser
        });
        dispatch({
          type: 'setModalData',
          modalData: {
            title: intl.messages['modal.title.success'],
            description: intl.messages['modal.editProfile.message'],
          },
        });
        if (redirect) {
          redirect();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingLayer.hide();
    }
    
    setSubmitting(false);
  }, [dispatch, intl, loadingLayer, setError, redirect]);

  return React.useMemo(() => (
    { user, edit: editData }
  ), [user, editData]);
};

export default useUser;
