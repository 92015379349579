import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as classnames from 'classnames';
import { AppContext } from '../../../storage/context';
import { postSaveCandidate, postContactCandidate } from '../../../api/services/User';
import { postTrackingEvent } from '../../../api/services/Tracking';
import { API_BASE_URL, LANGUAGE, LANGUAGES, PATHS, TRACKING_EVENT_NAMES } from '../../../utils/constants';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import DotDescriptionModal from '../DotDescriptionModal';
import Button34 from '../ui/Buttons/Button34';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const CandidateDetailsMobile = (props) => {
  const [{ helpers = {}, user = {} }, dispatch] = React.useContext(AppContext);
  const [showDotDescription, setShowDotDescription] = React.useState(false);
  const {
    candidate,
    sectionElements,
    previousButton=null,
    nextButton=null,
  } = props;

  const navigate = useNavigate();
  const intl = useIntl();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { tools, skills, languages, trackingEventTypes } = helpers;
  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const saveCandidate = async () => {
    if (!user.isGuest){
      if (user?.id && candidate?.id) {
        try {
          loadingLayer.show();
          const response = await postSaveCandidate(user.id, candidate.id, setError);
          if (response?.message) {
            dispatch({
              type: 'setModalData',
              modalData: {
                title: null,
                description: intl.messages[response.message],
              },
            });
          }
        } catch(err) {
          console.error(err);
        } finally {
          loadingLayer.hide();
        }
      }
      return;
    }
    openGuestModal()
  };

  const contactCandidate = () => {
    if (!user.isGuest){
      if (user?.id && candidate?.id) {
        dispatch({
          type: 'setFullScreenPromptModalData',
          fullScreenPromptModalData: {
            titles: [
              {
                text: intl.messages['modal.contactCandidate.title1'],
                className: '-secondary',
              },
              {
                text: intl.messages['modal.contactCandidate.title2'],
                className: '-white',
              },
            ],
            descriptions: [
              {
                text: intl.messages['modal.contactCandidate.description'],
                className: '-white',
              },
            ],
            option1: {
              label: intl.messages['modal.contactCandidate.option1Label'],
              handler: sendContactRequest,
            },
            option2: {
              label: intl.messages['modal.contactCandidate.option2Label'],
              handler: sendContactRequest,
            },
          }
        });
      }
      return;
    }
    openGuestModal()
  };

  const sendContactRequest = async (contactMethod) => {
    try {
      loadingLayer.show();
      const payload = {
        userId: user.id,
        candidateId: candidate.id,
        contactMethod,
      };

      const response = await postContactCandidate(payload, setError);
      if (response?.message) {
        dispatch({
          type: 'setModalData',
          modalData: {
            title: null,
            description: intl.messages[response.message],
          },
        });
      }
    } catch(err) {
      console.error(err);
    } finally {
      loadingLayer.hide();
    }
  };

  const navigateToProfile = () => {
    if(!user.isGuest){
      navigate(PATHS.profile)
      return;
    }
    openGuestModal()
  }

  const openGuestModal = () => {
    dispatch({
      type: 'setGuestModalData',
      guestModalData: {
        show: true,
        navigate: navigate
      },
    });
  }

  React.useEffect(() => {
    const trackPageVisit = async () => {
      const eventId = trackingEventTypes
        ?.find(eventType => eventType.name === TRACKING_EVENT_NAMES.CANDIDATE_DISPLAY)
        ?.id;
  
      if (user?.id && eventId && candidate?.id) {  
        try {
          loadingLayer.show();
  
          const payload = {
            userId: user.id,
            trackingEventTypeId: eventId,
            candidateId: candidate.id,
          };
  
          await postTrackingEvent(payload, setError);
        } catch (err) {
          console.error(err);
        } finally {
          loadingLayer.hide();
        }
      }
    };

    trackPageVisit();
  }, [user, candidate, loadingLayer, setError, trackingEventTypes]);

  return (
    <div className="candidate-details-mobile">
      <section
        className="candidate-details-mobile__section-profile"
        ref={sectionElements.profile}
        id="candidate-profile-section"
      >
        {previousButton}
        {nextButton}
        <p className="candidate-details-mobile__profile-number">
          {candidate?.profileNumber}
        </p>
        <div className="candidate-details-mobile__avatar-container">
          {candidate?.avatar ? (
            <div className="candidate-details-mobile__avatar">
              <img className="candidate-details-mobile__avatar-image" src={`${API_BASE_URL}${candidate.avatar}`} alt={candidate.profileName} />
            </div>
          ) : (
            <div className="candidate-details-mobile__avatar-placeholder">
              <FontAwesomeIcon icon={faUser} className="candidate-details-mobile__avatar-placeholder-image" />
            </div>
          )}
        </div>
        <div className="candidate-details-mobile__languages">
          {candidate?.languageIds.map(languageId => (
            <div key={languageId} className="candidate-details-mobile__language">
              <img
                className="candidate-details-mobile__language-icon"
                src={
                  languages
                  ? `${API_BASE_URL}${languages?.find(language => language.id === languageId)?.icon}`
                  : null
                }
                alt="lang-icon"
                title={languages?.find(language => language.id === languageId)?.[selectedLanguageCode].name}
              />
            </div>
          ))}
        </div>
        <div className="candidate-details-mobile__profile-buttons">
          <Button34
            className={classnames(
              'candidate-details-mobile__profile-button',
              '-secondary',
            )}
            label={intl.messages['common.save']}
            handleClick={saveCandidate}
          />
          <Button34
            className={classnames(
              'candidate-details-mobile__profile-button',
              '-primary-dark',
            )}
            label={intl.messages['common.getInTouch']}
            handleClick={contactCandidate}
          />
        </div>
        <p
          className="candidate-details-mobile__profile-title"
          dangerouslySetInnerHTML={{ __html: candidate?.[selectedLanguageCode].profileTitle }}
        />
        <p
          className="candidate-details-mobile__profile-description"
          dangerouslySetInnerHTML={{ __html: candidate?.[selectedLanguageCode].profileDescription }}
        />
        <div className="candidate-details-mobile__profile-link-container">
          <p
            className="candidate-details-mobile__profile-link"
            onClick={() => setShowDotDescription(true)}
          >
            {intl.messages['candidate.whatDotsMean']}
          </p>
            <DotDescriptionModal
              className="candidate-details-mobile__dot-description-modal"
              show={showDotDescription}
              closeModal={() => setShowDotDescription(false)}
            />
        </div>
      </section>
      <section
        className="candidate-details-mobile__section-skills"
        ref={sectionElements.skills}
        id="candidate-skills-section"
      >
        <div className="candidate-details-mobile__skills-container">
          <h3
            className="candidate-details-mobile__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.experienceIn'] }}
          ></h3>
          {candidate?.skillIds
          ?.map(skillId => skills?.find(skill => skill.id === skillId))
          .sort((skill1, skill2) => skill2.level - skill1.level)
          .map((skill, index, array) => (
            <Fragment key={index}>
              <div className="candidate-details-mobile__segment-skill">
                <p
                  className="candidate-details-mobile__segment-skill-name"
                  dangerouslySetInnerHTML={{ __html: skill?.[selectedLanguageCode].name }}
                ></p>
                {[1, 2, 3, 4, 5].map((position, i) => (
                  <div
                    key={position}
                    className={classnames(
                      'candidate-details-mobile__segment-skill-level-indicator',
                      i >= skill?.level && '-empty',
                    )}
                  ></div>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
        <div className="candidate-details-mobile__tools-container">
          <h3
            className="candidate-details-mobile__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.tools'] }}
          ></h3>
          {candidate?.tools
            ?.map(tool => tools?.find(toolEntity => toolEntity.id === tool.toolId))
            .sort((tool1, tool2) => tool2.level - tool1.level)
            .map((tool, index, array) => (
              <Fragment key={index}>
                <div className="candidate-details-mobile__segment-tool">
                  <p
                    className="candidate-details-mobile__segment-tool-name"
                    dangerouslySetInnerHTML={{ __html: tool?.name }}
                  ></p>
                  {[1, 2, 3, 4, 5].map((position, i) => (
                    <div
                      key={position}
                      className={classnames(
                        'candidate-details-mobile__segment-tool-level-indicator',
                        i >= tool?.level && '-empty',
                      )}
                    ></div>
                  ))}
                </div>
              </Fragment>
            ))}
        </div>
      </section>
      {candidate?.references?.length ? (
        <section
          className="candidate-details-mobile__section-references"
          ref={sectionElements.references}
          id="candidate-reference-section"
        >
          <h3
            className="candidate-details-mobile__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.references'] }}
          ></h3>
          {candidate.references.map((reference, index, array) => (
            <Fragment key={index}>
              <h4
                className="candidate-details-mobile__segment-subtitle"
                dangerouslySetInnerHTML={{ __html: reference[selectedLanguageCode].header }}
              ></h4>
              <p
                className="candidate-details-mobile__segment-description"
                dangerouslySetInnerHTML={{ __html: reference[selectedLanguageCode].description }}
              ></p>
              {index < array.length - 1 && (
                <div className="candidate-details-mobile__segment-separator"></div>
              )}
            </Fragment>
          ))}
        </section>
      ) : null}
      {candidate?.education?.length ? (
        <section
          className="candidate-details-mobile__section-education"
          ref={sectionElements.education}
          id="candidate-education-section"
        >
          <h3
            className="candidate-details-mobile__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.education'] }}
          ></h3>
          {candidate.education.map((education, index, array) => (
            <Fragment key={index}>
              <h4
                className="candidate-details-mobile__segment-subtitle"
                dangerouslySetInnerHTML={{ __html: education[selectedLanguageCode].header }}
              ></h4>
              <p
                className="candidate-details-mobile__segment-description"
                dangerouslySetInnerHTML={{ __html: education[selectedLanguageCode].description }}
              ></p>
              {index < array.length - 1 && (
                <div className="candidate-details-mobile__segment-separator"></div>
              )}
            </Fragment>
          ))}
        </section>
      ) : null}
      {candidate?.certifications?.length ? (
        <section
          className="candidate-details-mobile__section-certifications"
          ref={sectionElements.certifications}
          id="candidate-certifications-section"
        >
          <h3
            className="candidate-details-mobile__segment-title"
            dangerouslySetInnerHTML={{ __html: intl.messages['candidate.certifications'] }}
          ></h3>
          {candidate.certifications.map((certification, index, array) => (
            <Fragment key={index}>
              <h4
                className="candidate-details-mobile__segment-subtitle"
                dangerouslySetInnerHTML={{ __html: certification[selectedLanguageCode].header }}
              ></h4>
              <p
                className="candidate-details-mobile__segment-description"
                dangerouslySetInnerHTML={{ __html: certification[selectedLanguageCode].description }}
              ></p>
              {index < array.length - 1 && (
                <div className="candidate-details-mobile__segment-separator"></div>
              )}
            </Fragment>
          ))}
        </section>
      ) : null}
      <section
        className="candidate-details-mobile__section-bottom"
        ref={sectionElements.bottom}
        id="candidate-bottom-section"
      >
        <p
          className="candidate-details-mobile__segment-description"
          dangerouslySetInnerHTML={{ __html: intl.messages['candidate.accessSavedCandidates'] }}
        ></p>
        <Button34
          className={classnames(
            'candidate-details-mobile__button-my-site',
            '-primary-dark',
          )}
          label={intl.messages['common.mySite']}
          handleClick={navigateToProfile}
        />
      </section>
    </div>
  );
};

export default CandidateDetailsMobile;
