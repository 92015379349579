import React from 'react';
import { AppContext } from '../../storage/context';

const useLoadingLayer = () => {
  const [, dispatch] = React.useContext(AppContext);

  const show = React.useCallback(() => {
    dispatch({
      type: 'setIsLoading',
      isLoading: true,
    });
  }, [dispatch]);

  const hide = React.useCallback(() => {
    dispatch({
      type: 'setIsLoading',
      isLoading: false,
    });
  }, [dispatch]);

  return React.useMemo(() => (
    { show, hide }
  ), [show, hide]);
};

export default useLoadingLayer;
