import React from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../storage/context';
import { LANGUAGE, LANGUAGES, PATHS } from '../../../utils/constants';
import { createQueryFilterList } from '../../../utils/helpers';
import useLoadingLayer from '../../../utils/hooks/useLoadingLayer';
import useSetError from '../../../utils/hooks/useSetError';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import { getCandidates } from '../../../api/services/Candidates';
import ScreenResolver from '../../common/ScreenResolver';
import TopMenuLayerMobile from '../../common/TopMenuLayerMobile';
import CandidateDisplay from '../../common/CandidateDisplay';
import CandidateCardMobile from '../../common/CandidateCardMobile';
import BackButton from '../../common/ui/Buttons/BackButton';
import './index.scss';

const CandidatesCategoryPage = () => {
  const [ { helpers = {}, candidateCategoryList = [] }, dispatch ] = React.useContext(AppContext);

  const navigate = useNavigate();
  const intl = useIntl();
  const { mainCategorySlug, subcategorySlug } = useParams();
  const loadingLayer = useLoadingLayer();
  const setError = useSetError();

  const { mainCategories, subcategories } = helpers;
  const subcategoryId = subcategories?.find(subcategory => subcategory.slug === subcategorySlug)?.id;

  const selectedLanguageCode = localStorage.getItem(LANGUAGE) || LANGUAGES.DK;

  const navigateToCandidate = candidateId => {
    const path = `${PATHS.candidateByCategory}/${candidateId}`;
    const params = new URLSearchParams();
    params.append('mainCategorySlug', mainCategorySlug);
    params.append('subcategorySlug', subcategorySlug);

    navigate(`${path}?${params}`);
  };

  React.useEffect(() => {
    const fetchData = async () => {  
      try {
        if (subcategoryId) {
          const searchData = {
            subcategory: {
              value: subcategoryId
            },
          };

          loadingLayer.show();
          const candidatesData = await getCandidates(
            createQueryFilterList(searchData),
            setError,
          );
          dispatch({
            type: 'setCandidateCategoryList',
            candidateCategoryList: candidatesData,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingLayer.hide();
      }
    };

    fetchData();
  }, [dispatch, loadingLayer, setError, subcategoryId]);

  return (
    <div className="candidates-category-page">
      <div className="candidates-category-page__container">
        <TopMenuLayerMobile />
        <div className="candidates-category-page__mobile-title">
          {mainCategories?.find(category => category.slug === mainCategorySlug)?.[selectedLanguageCode].title}
          {'\u00A0/\u00A0'}
          {subcategories?.find(category => category.slug === subcategorySlug)?.[selectedLanguageCode].title}
        </div>
        <ScreenResolver
          large={BREAKPOINTS.md}
          desktop={
            <>
              <div className="candidates-category-page__header">
                <h1 className="candidates-category-page__title">
                  <span
                    className="candidates-category-page__main-category"
                    dangerouslySetInnerHTML={{ __html: mainCategories?.find(category => category.slug === mainCategorySlug)?.[selectedLanguageCode].title }}
                  ></span>
                  <span className="candidates-category-page__slash">{'\u00A0/\u00A0'}</span>
                  <span
                    className="candidates-category-page__subcategory"
                    dangerouslySetInnerHTML={{ __html: subcategories?.find(category => category.slug === subcategorySlug)?.[selectedLanguageCode].title }}
                  ></span>
                </h1>
              </div>
              <div className="candidates-category-page__content">
                <CandidateDisplay
                  candidates={candidateCategoryList}
                  navigateToCandidate={navigateToCandidate}
                />
              </div>
              <div className="candidates-category-page__footer" />
            </>
          }
          mobile={
            <div className="candidates-category-page__content">
              <div className="candidates-category-page__card-mobile-container">
                {candidateCategoryList.map(candidate => (
                  <CandidateCardMobile
                    key={candidate.id}
                    className="candidate-category-page__card-mobile"
                    candidate={candidate}
                    navigateToCandidate={navigateToCandidate}
                  />
                ))}
              </div>
            </div>
          }
        />
        <div className="candidates-category-page__bottom-section">
          {`${candidateCategoryList?.length || 0} `}
          {candidateCategoryList?.length === 1 ?
            intl.messages['candidateList.profile'] :
            intl.messages['candidateList.profiles']
          }
          <BackButton className="candidates-category-page__back-button" />
        </div>
      </div>
    </div>
  );
};

export default CandidatesCategoryPage;
