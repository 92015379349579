import protectedHttp from "../../ProtectedHTTP";
import { handleErrorSet } from "../../../utils/fetchUtils";
import Paths from "../../../utils/paths";

export const postSaveCandidate = async (userId, candidateId, setErrorStatus) => {
  try {
    const response = await protectedHttp.post(
      `/${Paths.User.savedCandidate}`,
      {
        data: {
          users_permissions_user: [ userId ],
          candidate: [ candidateId ],
        },
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const postContactCandidate = async (body, setErrorStatus) => {
  try {
    const response = await protectedHttp.post(
      `/${Paths.User.contactCandidate}`,
      {
        data: {
          users_permissions_user: [ body.userId ],
          candidate: [ body.candidateId ],
          contactMethod: body.contactMethod,
        },
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const postUserCheckEmail = async (body, setErrorStatus) => {
  try {
    const response = await protectedHttp.post(
      `/${Paths.User.checkEmail}`,
      {
        data: {
          email: body.userEmail,
        },
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const getDreamCandidates = async (setErrorStatus) => {
  try {
    const response = await protectedHttp.get(
      `/${Paths.User.dreamCandidate}`,
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const getDreamCandidate = async (dreamCandidateId, setErrorStatus) => {
  try {
    const response = await protectedHttp.get(
      `/${Paths.User.dreamCandidate}/${dreamCandidateId}`,
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const postDreamCandidate = async (body, setErrorStatus) => {
  try {
    const response = await protectedHttp.post(
      `/${Paths.User.dreamCandidate}`,
      {
        data: {
          users_permissions_user: body.userId,
          name: body.name,
        },
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const putDreamCandidate = async (dreamCandidateId, body, setErrorStatus) => {
  try {
    const response = await protectedHttp.put(
      `/${Paths.User.dreamCandidate}/${dreamCandidateId}`,
      {
        data: body,
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const deleteDreamCandidate = async (dreamCandidateId, setErrorStatus) => {
  try {
    const response = await protectedHttp.delete(
      `/${Paths.User.dreamCandidate}/${dreamCandidateId}`,
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    handleErrorSet(error, setErrorStatus);
  }
};

export const putEditProfile = async (body, setErrorStatus, intl) => {
  try {
    const response = await protectedHttp.put(
      `/${Paths.User.me}`,
      {
        data: body,
      },
    );
    const { data = {}, status = 0 } = response || {};

    if (data && status >= 200 && status < 300) {
      return response.data;
    } else {
      const [messages = {}] = (data && data.message) || [];
      const { messages: [errorObject] } = messages;
      const errorMessage = (errorObject && errorObject.message) || '';
      setErrorStatus({ text: errorMessage, code: 400 });
    }
  }
  catch (error) {
    const errorTranslationKey = error?.response?.data?.error?.message;
    if (errorTranslationKey) {
      const errorMessage = intl.messages[errorTranslationKey];
      handleErrorSet(error, setErrorStatus, errorMessage);
    } else {
      handleErrorSet(error, setErrorStatus);
    }
  }
};
