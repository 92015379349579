import React from 'react';
import * as classnames from 'classnames';
import './index.scss'; 

const MenuButtonBig = props => {
  const { className, title, description, image, handleClick, reversedLayout, disabled } = props;

  return (
    <div
      className={classnames(
        'menu-button-big',
        className && className,
        reversedLayout && '-reversed',
        disabled && '-disabled',
      )}
      onClick={handleClick}
    >
      <div
        className={classnames(
          'menu-button-big__text',
          reversedLayout && '-reversed',
          disabled && '-disabled',
        )}
      >
        <div
          className="menu-button-big__title"
          dangerouslySetInnerHTML={{ __html: title }}
        >
        </div>
        <div
          className="menu-button-big__description"
          dangerouslySetInnerHTML={{ __html: description }}
        >
        </div>
      </div>
      <div className="menu-button-big__image-container">
        <img className="menu-button-big__image" src={image} alt="illustration" />
      </div>
    </div>
  );
};

export default MenuButtonBig;
